import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../Components/Pagination/Pagination';
import Empty from '../../Components/Empty/Index';
import moment from 'moment';
import { useNavigate } from 'react-router';
import CustomizerContext from '../../_helper/Customizer';
import { downloadInvoiceData, fetchorders, isOpenStatusModal, statusToggle, statusUpdateOrderDetails } from '../../store/ordersSlice';
import { errorHandler } from '../../shared/_helper/responseHelper';
import CommonModal from '../../Components/Modals/modal';

const OrdersTable = () => {
  const storeVar = useSelector(state => state.order)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const statusModalToggle = () => dispatch(statusToggle());


  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    currentPage: 1,
    status: 'ordered',
    fromDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    orderId: '',
    stateStatus: ''
  });

  useEffect(() => {
    dispatch(fetchorders(formVar.limit, formVar.offset, formVar.status, formVar.fromDate, formVar.toDate))
  }, []);


  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(fetchorders(formVar.limit, offset, formVar.status,))

  };

  const handleDateChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, fromDate: e.target.value }));
    dispatch(fetchorders(formVar.limit, formVar.offset, formVar.status, e.target.value, formVar.toDate));
  }
  const handleDateendChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, toDate: e.target.value }))
    dispatch(fetchorders(formVar.limit, formVar.offset, formVar.status, formVar.fromDate, e.target.value));
  }
  const downloadInvoice = (id) => {
    if (id) {
      dispatch(downloadInvoiceData(id));
    }
    else {
    }
  }
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(fetchorders(formVar.limit, formVar.offset, e.target.value, formVar.fromDate, formVar.toDate))
  };
  const getDateTime = (date) => {
    if (date) {
      return moment(date).format('HH:mm Do MMM YY')
    }
  }
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      orderId: data._id,
      stateStatus: data.status,
    }))
  }
  const onValueChange = (event) => {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      stateStatus: event.target.value,


    }))
  }
  const submitStatus = () => {
    dispatch(statusUpdateOrderDetails(formVar.orderId, formVar.stateStatus))
  }

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="4">
                <Label className="col-form-label" htmlFor="recipient-name">From Date</Label>
              </Col>
              <Col md="4">
                <Label className="col-form-label" htmlFor="recipient-name">To Date</Label>
              </Col>
              <Col md="4">
                <Label className="col-form-label" htmlFor="recipient-name">Order Status</Label>
              </Col>

            </Row>
            <Row>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" max={moment().format('YYYY-MM-DD')} name="select" type="DATE"
                  value={formVar.fromDate} onChange={handleDateChange}>
                </Input>
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" max={moment().format('YYYY-MM-DD')} name="select" type="DATE"
                  value={formVar.toDate} onChange={handleDateendChange}>
                </Input>
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ordered'>ORDERED</option>
                  <option value='dispatched'>DISPATCHED</option>
                  <option value='delivered'>DELIVERED</option>
                  <option value='cancelled'>CANCELLED</option>
                </Input>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Order Time</th>
                  <th scope='col'>OrderId</th>
                  <th scope='col'>TransactionId</th>
                  <th scope='col'>Mrp</th>
                  <th scope='col'>Final Price</th>
                  <th scope='col'>Quantity</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.ordersData?.map((item, index) => (
                  <tr key={item._id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{getDateTime(item.orderedTime)}</td>
                    <td>{item.orderID}</td>
                    <td>{item.transactionId}</td>
                    <td>{item.totalMRP}</td>
                    <td>{item.totalFinalPrice}</td>
                    <td>{item.totalQuantity}</td>


                    <td>
                      {
                        item.status === 'ordered' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'ordered' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status.toUpperCase()}</span>
                          </span>
                        </>
                      }
                      {
                        item.status === 'dispatched' && <>
                          <span className={`font-info rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'dispatched' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status.toUpperCase()}</span>
                          </span>
                        </>
                      }
                      {
                        item.status === 'delivered' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'delivered' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status.toUpperCase()}</span>
                          </span>
                        </>
                      }
                      {
                        item.status === 'cancelled' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'cancelled' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status.toUpperCase()}</span>
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2 align-items-center'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-info-circle fa-lg" onClick={(e) => navigate('/orders-details/' + layoutURL + '?id=' + item._id)}></i>
                          <div className="tooltipCustom">More Details</div>
                        </div>
                        <div className='cursor-pointer bg-light-info font-success action-icon'>
                          <i className="fa fa-download fa-lg" onClick={(e) => downloadInvoice(item?._id)}></i>
                          <div className="tooltipCustom">Download Invoice</div>
                        </div>
                        <div className='cursor-pointer bg-light-info font-info action-icon'>
                          <i className="fa fa-toggle-on fa-lg" onClick={(e) => statusToggleModal(item)}></i>
                          <div className="tooltipCustom">Order Status Update</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {
            storeVar.ordersCount <= 0 && (
              <Empty />
            )
          }
        </Card>
        {
          storeVar.ordersCount > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.ordersCount}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>

      <CommonModal isOpen={storeVar.isStatusOpenModal} title={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={formVar.stateStatus === 'ordered'} onChange={onValueChange} value='ordered' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ORDERED</span>
              </Label>
            </div>

            <div className='radio radio-primary'>
              <Input id='radioinline2' type='radio' className="radio_animated" name='radio2' checked={formVar.stateStatus === 'dispatched'} onChange={onValueChange} value='dispatched' />
              <Label className='mb-0' for='radioinline2'>
                <span className='digits'>DISPATCHED</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={formVar.stateStatus === 'delivered'} onChange={onValueChange} value='delivered' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>DELIVERED</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>

    </Fragment>
  );
};

export default OrdersTable;
