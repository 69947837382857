import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  productsData: [],
  totalproducts: 0,
  isOpenModal: false,
  isStatusOpenModal: false,
}

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setproductsData(state, { payload }) {
      state.productsData = payload.result
      state.totalproducts = payload.total
    },
    updateproductsData(state, { payload }) {
      const objIndex = state.productsData.findIndex((obj) => obj._id === payload._id);
      if (objIndex >= 0) {
        state.productsData[objIndex] = payload
      }
    },
    updateproductsStatusData(state, { payload }) {
      const objIndex = state.productsData.findIndex((obj) => obj._id === payload.id);
      if (objIndex >= 0) {
        state.productsData[objIndex].status = payload.status
      }
    },
    pushproductsData(state, { payload }) {
      state.productsData.push(payload)
    },

    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    }
  }
})

export const { setproductsData, updateproductsData, updateproductsStatusData, pushproductsData, isOpenModal,
  ModalToggle, isOpenStatusModal, statusToggle } = productsSlice.actions;

export default productsSlice.reducer;

export function getproducts(limit, offset, status, keyword) {
  return async function getproductsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getproducts(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setproductsData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function addproducts(payload) {
  return async function addproductsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addproducts(payload).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(pushproductsData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateproducts(id, data) {
  return async function updateproductsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateproducts(id, data).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updateproductsData(response.data))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdateproducts(payload) {
  return async function statusUpdateproductsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.UpdateStatusProducts(payload).then(
        (response) => {
          dispatch(statusToggle())
          dispatch(updateproductsStatusData(payload))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

