
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import SweetAlert from 'sweetalert2';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,

};

export const podcastSlice = createSlice({
  name: 'podcast',
  initialState,
  reducers: {
    setpodcasts(state, { payload }) {
      state.podcastData = payload;
    },
    pushPodcastData(state, { payload }) {
      state.podcastData.push(payload)
    },
    updatepodcastsData(state, { payload }) {
      const objIndex = state.podcastData.findIndex((obj) => obj._id === payload._id);
      if (objIndex >= 0) {
        state.podcastData[objIndex] = payload
      }
    },
    updatepodcastsImage(state, { payload }) {
      console.log(payload);
      
      const objIndex = state.podcastData.findIndex((obj) => obj._id === payload._id);
      if (objIndex >= 0) {        
        state.podcastData[objIndex].image = payload.image
      }
    },
    slicePodcastData(state, { payload }) {
      const objIndex = state.podcastData.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.podcastData.splice(objIndex, 1)
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  },
});

export const { setpodcasts, pushPodcastData, updatepodcastsData, updatepodcastsImage, slicePodcastData, isOpenModal, isImageOpenModal, ModalToggle, ImagestatusToggle } = podcastSlice.actions;
export default podcastSlice.reducer;

export function fetchpodcasts() {
  return async function fetchpodcastsThunk(dispatch, getState) {
    try {
      await service.getpodcasts().then(
        (response) => {

          dispatch(setpodcasts(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}
export function addpodcasts(data) {

  return async function addpodcastssThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.createpodcasts(data).then(
        (response) => {
          dispatch(pushPodcastData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updatePodcast(id, data) {
  return async function updatepodcastsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.patchPodcast(id, data).then(
        (response) => {
          dispatch(updatepodcastsData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImagepodcasts(id, file) {

  return async function updatepodcastsImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatepodcastsImage(id, file).then(
        (response) => {
          dispatch(updatepodcastsImage(response.data))
          dispatch(isImageOpenModal())
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}



export function DeletePodcast(id) {
  return async function statusDeletepodcastsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.deletePodcastStatus(id).then(
        (response) => {
          dispatch(slicePodcastData(id))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

