
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  ordersData: [],
  ordersCount: 0,
  orderDetails: [],
  isStatusOpenModal: false,

};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setorders(state, { payload }) {
      state.ordersData = payload.result;
      state.ordersCount = payload.count;
    },
    setOrderDetails(state, { payload }) {
      state.orderDetails = payload;
    },
    updateOrderData(state, { payload }) {
      const objIndex = state.ordersData.findIndex((obj) => obj._id === payload.id);
      if (objIndex >= 0) {
        state.ordersData[objIndex].status = payload.status
      }
    },
    downloadFile(state, { payload }) {

      if (
        window.navigator &&
        window.navigator.msSaveOrOpenBlob
      ) return window.navigator.msSaveOrOpenBlob(payload.pdfData);

      const data = window.URL.createObjectURL(payload.pdfData);

      const link = document.createElement('a');
      link.href = data;
      link.download = payload.fileName;

      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
  },
});

export const { setorders, downloadFile, setOrderDetails, updateOrderData, isOpenStatusModal, statusToggle, } = ordersSlice.actions;
export default ordersSlice.reducer;

export function fetchorders(limit, offset, status, fromDate, toDate) {
  return async function fetchorderssThunk(dispatch, getState) {
    dispatch(setLoading(true))
    try {
      await service.getorders(limit, offset, status, fromDate, toDate).then(
        (response) => {
          dispatch(setorders(response.data));
          dispatch(setLoading(false))
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}

export function downloadInvoiceData(id) {
  return async function downloadInvoiceDataThunk(dispatch, getState) {
    try {
      await service.downloadInvoicePdf(id).then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
        }, (error) => {
        }
      );
    } catch (err) {
    }
  }
}

export function fetchOrderDetails(id) {
  return async function fetchOrderDetailsThunk(dispatch, getState) {
    dispatch(setLoading(true))
    try {
      await service.getOrderDetails(id).then(
        (response) => {
          dispatch(setOrderDetails(response.data))
          dispatch(setLoading(false))


        }, (error) => {
        }
      );
    } catch (err) {
    }
  }
}
export function statusUpdateOrderDetails(id, status) {
  return async function statusUpdateOrderDetailsThunk(dispatch, getState) {
    dispatch(setLoading(true))
    try {
      await service.statusUpdateOrder(id, status).then(
        (response) => {
          dispatch(updateOrderData({ id, status }))
          dispatch(statusToggle())
          dispatch(setLoading(false))


        }, (error) => {
        }
      );
    } catch (err) {
    }
  }
}
