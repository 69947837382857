import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { useLocation } from 'react-router-dom';
import { CheckCircle, XCircle, Edit, Trash2, FileText } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../../Components/Modals/modal';
import SweetAlert from 'sweetalert2';
import { addproductKeyword, getproductKeyword, isOpenModal, ModalToggle, statusDeleteKeyword } from '../../../store/productKeywordSlice';

const ProductKeywordTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const storeVar = useSelector(state => state.productKeyword)  
  const params = new URLSearchParams(location.search);
  const productId = params.get('id');  
  const toggle = () => dispatch(ModalToggle());
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    modalTitle: null,
    keyword: '',
  });

  useEffect(() => {
    dispatch(getproductKeyword(productId))
  }, []);




  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Keywords',
      keyword: '',
    }))
  }


  const submitKeywords = () => {

    if (keywordValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    dispatch(addproductKeyword({id: productId, keywords: formVar.keyword}))

  }


  const keywordValid = () => {
    if (!formVar.keyword) {
      return "Keywords is required";
    }
  }

  const BannerDelete = (data) => {
    console.log(data);
    
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(statusDeleteKeyword({id: productId, keywords: data}))
        }
      });
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">

              </Col>
              <Col md="4">

              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>
                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add Keywords
                  </Btn>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>KeyWord</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.productKeywordData?.map((item, index) => (
                  <tr key={index}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item} </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => BannerDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            {!formVar.editState && <>
              <Label className="col-form-label" for="recipient-name">Keyword</Label>
              <Input className="form-control" type="text" placeholder='Keyword' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))} value={formVar.keyword} />
              {submit && keywordValid() ? <span className='d-block font-danger'>{keywordValid()}</span> : ""}
            </>}
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitKeywords }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default ProductKeywordTable;
