import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  specializationData: [],
  isOpenModal: false,
  isStatusOpenModal:false,
}

export const specializationSlice = createSlice({
  name: "specialization",
  initialState,
  reducers: {
    setspecializationData(state, { payload }) {
      state.specializationData = payload
    },
    updatespecializationData(state, { payload }) {      
      const objIndex = state.specializationData.findIndex((obj) => obj._id === payload._id);
      if(objIndex>=0){
        state.specializationData[objIndex]=payload
      }
    },
    pushspecializationData (state,{payload}){
      state.specializationData.push(payload)
    },
    slicespecializationData(state, { payload }) {
      const objIndex = state.specializationData.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.specializationData.splice(objIndex, 1)
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
  }
})

export const { setspecializationData, updatespecializationData, pushspecializationData, slicespecializationData, isOpenModal, ModalToggle } = specializationSlice.actions;

export default specializationSlice.reducer;

export function getspecialization() {
  return async function getspecializationThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getSpecialization().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setspecializationData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addspecialization(payload) {
  return async function addspecializationThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addspecialization(payload).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(pushspecializationData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updatespecialization(id,name) {
  return async function updatespecializationThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateSpecialization(id,name).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updatespecializationData(response.data))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function deletespecialization(id) {
  return async function deletespecializationThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.deleteSpecialization(id).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(slicespecializationData(id))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}