import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  subCategoryData: [],
  totalsubCategory:0,
  isOpenModal: false,
  isStatusOpenModal:false,
}

export const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    setsubCategoryData(state, { payload }) {
      state.subCategoryData = payload.result
      state.totalsubCategory = payload.total
    },
    updatesubCategoryData(state, { payload }) {     
      const objIndex = state.subCategoryData.findIndex((obj) => obj._id === payload._id);
      if(objIndex>=0){
        state.subCategoryData[objIndex]=payload
      }
    },
    pushsubCategoryData (state,{payload}){
      state.subCategoryData.push(payload)
    },

    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    sliceSubCategoryData(state, { payload }) {
      const objIndex = state.subCategoryData.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.subCategoryData.splice(objIndex, 1)
      }
    },
  }
})

export const { setsubCategoryData, updatesubCategoryData,pushsubCategoryData, isOpenModal, 
  ModalToggle,setFaqsSpecializationData,isOpenStatusModal,statusToggle, sliceSubCategoryData } = subCategorySlice.actions;

export default subCategorySlice.reducer;

export function getsubCategory(limit, offset, status, keyword) {
  return async function getsubCategoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getsubCategory(limit, offset, status, keyword).then(
        (response) => {          
          dispatch(setLoading(false))
          dispatch(setsubCategoryData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addsubCategory(payload) {
  return async function addsubCategoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addSubCategory(payload).then(
        (response) => {          
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(pushsubCategoryData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updatesubCategory(id,name) {
  return async function updatesubCategoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updatesubCategory(id,name).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updatesubCategoryData(response.data))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdatesubCategory(payload) {
  return async function statusUpdatesubCategoryThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdatesubCategory(payload._id,payload.status).then(
        (response) => {
          dispatch(statusToggle())
          dispatch(updatesubCategoryData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function deleteSubCategory(id) {
  return async function statusUpdatesubCategoryThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdatesubCategory(id, 'deleted').then(
        (response) => {
          dispatch(sliceSubCategoryData(id))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
