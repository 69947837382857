import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE, 
  bankDetailsData: [],


}

export const bankDetailsSlice = createSlice({
  name: "bankDetails",
  initialState,
  reducers: {
    setbankDetailsData(state, { payload }) {
      state.bankDetailsData = payload      
    },
   
  }
})

export const { setbankDetailsData } = bankDetailsSlice.actions;

export default bankDetailsSlice.reducer;

export function getbankDetailsList(id) {
  return async function getbankDetailsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getbankDetails(id).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setbankDetailsData(response.data))
        
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
