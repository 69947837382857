import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import ExamDetailsTable from './ExamDetailsTable';
const ExamDetails = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Astrologers' parent='Exam Details'  />
      <Container fluid={true}>
        <Row>
          <ExamDetailsTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default ExamDetails;
