import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  examDetailsData: [],
}

export const examDetailsSlice = createSlice({
  name: "examDetails",
  initialState,
  reducers: {
    setexamDetailsData(state, { payload }) {      
      state.examDetailsData = payload.result
      console.log(payload.result);
      
    }
  }
})

export const { setexamDetailsData } = examDetailsSlice.actions;

export default examDetailsSlice.reducer;

export function getexamDetails(id) {
  return async function getexamDetailsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getexamDetails(id).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setexamDetailsData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
