import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import ExamTable from './ExamTable';

const Exam = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Exam' parent='Exam'  />
      <Container fluid={true}>
        <Row>
          <ExamTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Exam;
