import React, { Fragment, useEffect } from 'react';
import { Col, Card, Table, Row, Label, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getastrologerById } from '../../store/astrologerSlice';
import { getbankDetailsList } from '../../store/bankDetailsSlice';
import NoImage from '../../assets/images/noimage.png';


const AstroDetailsTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const storeVar = useSelector(state => state.astrologer)
  const bankDetailsVar = useSelector(state => state.bankDetails)
  console.log(storeVar);

  const astrlogerId = new URLSearchParams(location.search).get('id');

  useEffect(() => {
    dispatch(getastrologerById(astrlogerId))
    dispatch(getbankDetailsList(astrlogerId))
  }, []);

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Label className="col-form-label font-bold h-4-r font-1-5-r" for="recipient-name">Profile Details</Label>
          <Row >
            <Col md="4" className='h-2-r ' >
              {"Display Name:- " + storeVar.astrologerDetails.name}
            </Col>
            <Col md="4" className='h-2-r' >
              {"Real Name:- " + storeVar.astrologerDetails.realName}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Email:- " + storeVar.astrologerDetails.email}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Phone Number:- " + storeVar.astrologerDetails?.phoneNumber}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Date Of Birth:- " + storeVar.astrologerDetails?.dob}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Gender:- " + storeVar.astrologerDetails?.gender}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Address:-" + storeVar.astrologerDetails.city + ', ' + storeVar.astrologerDetails.address}
            </Col>
          </Row>

          <Label className="col-form-label font-bold h-4-r font-1-5-r" for="recipient-name">Other Details</Label>
          <Row>
            <Col md="4" className='h-2-r'>
              {"Level:- " + storeVar.astrologerDetails.level || ''}
            </Col>
            <Col md="4" className='h-2-r'>
              {"College:- " + storeVar.astrologerDetails.college || ''}
            </Col>
            <Col md="4" className='h-2-r'>
              {"University:- " + storeVar.astrologerDetails.university || ''}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Experience:- " + storeVar.astrologerDetails?.experience + "Yr"}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Qualification:- " + storeVar.astrologerDetails?.qualification}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Daily Contribution:-" + storeVar.astrologerDetails.dailyContribute}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Income Source:-" + storeVar.astrologerDetails.incomeSource}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Working:-" + storeVar.astrologerDetails.isWorking}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Puja:-" + storeVar.astrologerDetails.isPuja}
            </Col>


          </Row>


          <Label className="col-form-label font-bold h-4-r font-1-5-r" for="recipient-name">Price Details</Label>
          <Row>
            <Col md="4" className='h-2-r'>
              {"Chat Price:- ₹" + storeVar.astrologerDetails.chatPrice}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Call Price:- ₹" + storeVar.astrologerDetails.callPrice}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Video Call Price:- ₹" + storeVar.astrologerDetails.videoPrice}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Admin Commission:- " + storeVar.astrologerDetails.adminCommission + "%"}
            </Col>

          </Row>
          <Label className="col-form-label font-bold h-4-r font-1-5-r" for="recipient-name">Specialization</Label>
          <Row >
            {storeVar.astrologerDetails.specializations?.map((item, index) => (
              <Col md="2"  key={index} className='h-2-r ' >
                {item.name}
              </Col>
            )
            )}
          </Row>
          <Label className="col-form-label font-bold h-4-r font-1-5-r" for="recipient-name">Languages</Label>
          <Row >
            {storeVar.astrologerDetails.language?.map((item, index) => (
              <Col md="2"  key={index} className='h-2-r ' >
                {item}
              </Col>
            )
            )}
          </Row>

          <Label className="col-form-label font-bold h-4-r font-1-5-r" for="recipient-name">Bank Details</Label>
          <Row>
            <Col md="4" className='h-2-r'>
              {"Account Holder Name:- " + (bankDetailsVar.bankDetailsData.name || '')}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Account No:- " + (bankDetailsVar.bankDetailsData.accountNo || '')}
            </Col>
            <Col md="4" className='h-2-r'>
              {"IFSC code:- " + (bankDetailsVar.bankDetailsData.ifsc || '')}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Bank Name:- " + (bankDetailsVar.bankDetailsData.bankname || '')}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Branch Name:- " + (bankDetailsVar.bankDetailsData.branchName || '')}
            </Col>
          </Row>

          <Label className="col-form-label font-bold h-4-r font-1-5-r" for="recipient-name">Images</Label>
          <Row className='h-10-r'>
            <Col md="4" className='h-2-r ' >
              {"Profile:- "}
              {storeVar.astrologerDetails.profile ? <img className='w-10-r h-5-r' src={storeVar.astrologerDetails.profile} alt="" /> : <img className='w-10-r h-5-r' src={NoImage} alt="" />}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Aadhar Card:- "}
              {storeVar.astrologerDetails.aadharImage ? <img className='w-10-r h-5-r' src={storeVar.astrologerDetails.aadharImage} alt="" /> : <img className='w-10-r h-5-r' src={NoImage} alt="" />}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Pancard:- "}
              {storeVar.astrologerDetails.pancardImage ? <img className='w-10-r h-5-r' src={storeVar.astrologerDetails.pancardImage} alt="" /> : <img className='w-10-r h-5-r' src={NoImage} alt="" />}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Bank/ Cancelled Cheque:- "}
              {storeVar.astrologerDetails.bankAccount ? <img className='w-10-r h-5-r' src={storeVar.astrologerDetails.bankAccount} alt="" /> : <img className='w-10-r h-5-r' src={NoImage} alt="" />}
            </Col>
          </Row>

        </CardBody>

      </Card>
    </Fragment>
  );
};

export default AstroDetailsTable;
