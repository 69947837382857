import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler } from '../shared/_helper/responseHelper';

const initialState = {
  data: [],
  total: 0,
}

export const audioCallSlice = createSlice({
  name: "audioCall",
  initialState,
  reducers: {
    setaudioCallData(state, { payload }) {
      state.data = payload.result
      state.total = payload.total
    },
  }
})

export const { setaudioCallData } = audioCallSlice.actions;

export default audioCallSlice.reducer;

export function getaudioCall(astrologerId, limit, offset) {  
  return async function getaudioCallThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getAudioCall(astrologerId, limit, offset).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setaudioCallData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
