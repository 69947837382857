import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  examData: [],
  totalexam: 0,
  isOpenModal: false,
  isStatusOpenModal: false,
}

export const examSlice = createSlice({
  name: "exam",
  initialState,
  reducers: {
    setexamData(state, { payload }) {
      state.examData = payload
    },
    updateexamData(state, { payload }) {
      const objIndex = state.examData.findIndex((obj) => obj._id === payload._id);
      if (objIndex >= 0) {
        state.examData[objIndex] = payload
      }
    },
    pushexamData(state, { payload }) {
      state.examData.push(payload)
    },

    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    sliceexamData(state, { payload }) {
      const objIndex = state.examData.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.examData.splice(objIndex, 1)
      }
    },
  }
})

export const { setexamData, updateexamData, pushexamData, isOpenModal, ModalToggle, sliceexamData } = examSlice.actions;

export default examSlice.reducer;

export function getExam(limit, offset, status, keyword) {
  return async function getexamThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.fetchExam(limit, offset, status, keyword).then(
        (response) => {          
          dispatch(setLoading(false))
          dispatch(setexamData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addexam(payload) {
  return async function addexamThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.examAdd(payload).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(pushexamData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateexam(id, data) {
  return async function updateexamThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.examUpdate(id, data).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updateexamData(response.data))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function deleteexam(id) {
  return async function deleteexamThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.examDelete(id).then(
        (response) => {
          dispatch(sliceexamData(id))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
