import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../../Components/Modals/modal';
import Pagination from '../../../Components/Pagination/Pagination';
import Empty from '../../../Components/Empty/Index';
import moment from 'moment';
import { getCategory, ModalToggle, isOpenModal, isOpenStatusModal, statusToggle, updateCategory, addCategory, statusUpdateCategory, deleteCategory, isImageOpenModal, updateImageCategory } from '../../../store/categorySlice';
import SweetAlert from 'sweetalert2';
import NoImage from '../../../assets/images/noimage.png';
import Dropzone from 'react-dropzone-uploader';
import { getexamDetails } from '../../../store/examDetails.slice';
import { useLocation } from 'react-router-dom';


const ExamDetailsTable = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const astrlogerId = new URLSearchParams(location.search).get('id');
    const storeVar = useSelector(state => state.exam)
    console.log(storeVar);

    useEffect(() => {
        dispatch(getexamDetails(astrlogerId))

    }, []);


    return (
        <Fragment>
            <Col sm='12'>
                <Card>
                    <div className='table-responsive'>
                        <Table hover={true} className='table-border-horizontal table-light'>
                            <thead>
                                <tr>
                                    <th scope='col'>Sl.No</th>
                                    <th scope='col'>Question</th>

                                </tr>
                            </thead>
                            <tbody>
                                {storeVar?.examDetailsData?.map((item, index) => (
                                    <tr key={index}>
                                        <th scope='row'>{index + 1}</th>
                                        <td >{item.questionId.questionText}
                                            <Col md="12" >
                                                <Row>
                                                    {item.questionId.answers.map((data, index) => {
                                                        if (index === 0 || index === 1) {
                                                            return (
                                                                <Col key={index} md="6" className='h-2-r' style={{
                                                                    color:
                                                                      data._id === item.selectedAnswerId
                                                                        ? data.isCorrect
                                                                          ? "green"
                                                                          : "red"
                                                                        : "black",
                                                                  }}>
                                                                    {data.text}
                                                                </Col>
                                                            );
                                                        }
                                                        return null; 
                                                    })}
                                                </Row>
                                                <Row>
                                                    {item.questionId.answers.map((data, index) => {
                                                        if (index === 2 || index === 3) {
                                                            return (
                                                                <Col key={index} md="6" className='h-2-r' style={{
                                                                    color:
                                                                      data._id === item.selectedAnswerId
                                                                        ? data.isCorrect
                                                                          ? "green"
                                                                          : "red"
                                                                        : "black", 
                                                                  }}>
                                                                    {data.text}
                                                                </Col>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Row>
                                            </Col>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                </Card>

            </Col>
        </Fragment>
    );
};

export default ExamDetailsTable;
