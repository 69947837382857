import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler } from '../shared/_helper/responseHelper';

const initialState = {
  data: [],
  total: 0,
}

export const videoCallSlice = createSlice({
  name: "videoCall",
  initialState,
  reducers: {
    setvideoCallData(state, { payload }) {
      state.data = payload.result
      state.total = payload.total
    },
  }
})

export const { setvideoCallData } = videoCallSlice.actions;

export default videoCallSlice.reducer;

export function getvideoCall(astrologerId, limit, offset) {  
  return async function getvideoCallThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getVideoCall(astrologerId, limit, offset).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setvideoCallData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
