import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import { errorHandler } from '../shared/_helper/responseHelper';

const initialState = {
  activeVideos: 0,
  categories: 0,
  deactiveVideos: 0,
  languages: 0,
  astro: 0,
  user: 0,
  products: 0,
  catagory: 0,
  orders: 0,
  coupons: 0,
  blogs: 0,
  podcasts: 0,
  subCatagory: 0,
  failedOrder: 0,
  dispatchedOrder:0,
  deliveredOrder:0,
}

export const loadingSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardData(state, { payload }) {
      state.activeVideos = payload.activeVideos
      state.categories = payload.categories
      state.deactiveVideos = payload.deactiveVideos
      state.languages = payload.languages
    },
    setTotalAstrologer(state, {payload}) {
      state.astro = payload
    },
    setTotalUser(state, {payload}) {
      state.user = payload
    },
    setTotalProducts(state, {payload}) {
      state.products = payload
    },
    setTotalCatagory(state, {payload}) {
      state.catagory = payload
    },
    setTotalOrders(state, {payload}) {
      state.orders = payload
    },
    setTotalCoupons(state, {payload}) {
      state.coupons = payload
    },
    setTotalBlogs(state, {payload}) {
      state.blogs = payload
    },
    setTotalPodcasts(state, {payload}) {
      state.podcasts = payload
    },
    setTotalSubCatagory(state, {payload}) {
      state.subCatagory = payload
    },
    setTotalFailedOrder(state, {payload}) {
      state.failedOrder = payload
    },
    setTotalDispatchedOrder(state, {payload}) {
      state.dispatchedOrder = payload
    },
    setTotalDeliveredOrder(state, {payload}) {
      state.deliveredOrder = payload
    },

  }
})

export const { setDashboardData, setTotalAstrologer, setTotalUser, setTotalProducts, setTotalCatagory, setTotalOrders, 
              setTotalCoupons, setTotalPodcasts, setTotalBlogs, setTotalSubCatagory, setTotalFailedOrder, setTotalDispatchedOrder, setTotalDeliveredOrder } = loadingSlice.actions;

export default loadingSlice.reducer;

/*LOGIN GET OTP*/
export function getDashboardData() {
  return async function getDashboardDataThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getDashboard().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setDashboardData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function getAllAstrologer() {
  return async function getAllAstrologerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getAstologerTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalAstrologer(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function getAllUser() {
  return async function getAllUserThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getUserTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalUser(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function getAllProducts() {
  return async function getAllProductsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getProductsTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalProducts(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function getAllCatagory() {
  return async function getAllCatagoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getCatagoryTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalCatagory(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function getAllOrder() {
  return async function getAllOrderThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getOrdersTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalOrders(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
      await service.getDispatchedOrderTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalDispatchedOrder(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
      await service.getCancelledOrderTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalFailedOrder(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
      await service.getDeliveredOrderTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalDeliveredOrder(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function getAllCoupons() {
  return async function getAllCouponsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getCouponsTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalCoupons(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function getAllBlogs() {
  return async function getAllBlogsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getBlogsTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalBlogs(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function getAllPodcasts() {
  return async function getAllPodcastsThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getPodcastTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalPodcasts(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function getAllSubCatagory() {
  return async function getAllSubCatagoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getSubCatagoryTotal().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setTotalSubCatagory(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}