import React, { Fragment, useEffect } from 'react';
import { Col, Card, Table, Row, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchOrderDetails } from '../../../store/ordersSlice';
import moment from 'moment';


const OrderDetailsTable = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const storeVar = useSelector(state => state.order)
    const paymentId = new URLSearchParams(location.search).get('id');

    useEffect(() => {
        dispatch(fetchOrderDetails(paymentId))
    }, []);

    return (
        <Fragment>
            <Card>
                <Row>
                    <Col md="4" className='pad-2-a'>
                        {"Order Id:- " + storeVar.orderDetails.orderId}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"payment Id:- " + storeVar.orderDetails.transactionId}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Order Status:- " + storeVar.orderDetails?.status?.toUpperCase()}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Order date:- " + moment(storeVar.orderDetails.orderedTime).format('HH:mm Do MMM YY')}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Mrp:- " + storeVar.orderDetails.totalMrp}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Discount:- " + storeVar.orderDetails.discount}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Total:- " + storeVar.orderDetails.totalFinalPrice}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Coupon Code:- " + (storeVar.orderDetails.couponCode?.code || 'No Coupons')}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Coupon Discount Amount:- " + (storeVar.orderDetails.couponCode?.discountAmount || 'No Coupons')}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Final Price:- " + (
                            storeVar.orderDetails.totalFinalPrice -
                            (storeVar.orderDetails.couponCode && storeVar.orderDetails.couponCode !== ""
                                ? storeVar.orderDetails.couponCode.discountAmount || 0
                                : 0)
                        )}
                    </Col>
                </Row>
            </Card>
            <Label className="col-form-label font-bold" >User Details:-</Label>

            <Card>
                <Row >
                    <Col md="4" className='pad-2-a'>
                        {"Name:- " + storeVar.orderDetails.address?.name}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Email:- " + storeVar.orderDetails.address?.addressLine1}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Phone Number:- " + storeVar.orderDetails.address?.phoneNumber}
                    </Col>
                </Row>
            </Card>
            <Label className="col-form-label font-bold" >Address:-</Label>

            <Card>
                <Row>
                    <Col md="4" className='pad-2-a'>
                        {"Name:- " + storeVar.orderDetails.address?.name}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Address Line 1:- " + storeVar.orderDetails.address?.addressLine1}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Address Line 2:- " + storeVar.orderDetails?.address?.addressLine2}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"City:- " + storeVar.orderDetails?.address?.city}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"State:- " + storeVar.orderDetails.address?.state}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Pin Code:- " + storeVar.orderDetails.address?.postalCode}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Phone Number:- " + storeVar.orderDetails.address?.phoneNumber}
                    </Col>
                    <Col md="4" className='pad-2-a'>
                        {"Alt Phone Number:- " + storeVar.orderDetails?.address?.altPhoneNumber || ''}
                    </Col>
                </Row>
            </Card>
            <div className='table-responsive'>
                <Table hover={true} className='table-border-horizontal table-light'>
                    <thead>
                        <tr>
                            <th scope='col'>Sl.No</th>
                            <th scope='col'>Name</th>
                            <th scope='col'>SkuId</th>
                            <th scope='col'>Quantity</th>
                            <th scope='col'>Image</th>
                        </tr>
                    </thead>
                    <tbody>
                        {storeVar.orderDetails.product?.map((item, index) => (
                            <tr key={item._id}>
                                <th scope='row'>{index + 1}</th>
                                <td>{item.productId.name}</td>
                                <td>{item.productId.skuId}</td>
                                <td>{item.quantity}</td>
                                <td className='w-25'>
                                    <img className='w-25 h-6-r' src={item.productId?.images[0]?.image} alt="" />
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    );
};

export default OrderDetailsTable;