import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  languageData: [],
  totalLanguage:0,
  isOpenModal: false,
  isStatusOpenModal:false,
}

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguageData(state, { payload }) {
      state.languageData = payload.result
      state.totalLanguage = payload.count
    },
    updateLanguageData(state, { payload }) {
      const objIndex = state.languageData.findIndex((obj) => obj.id === payload.id);
      if(objIndex>=0){
        state.languageData[objIndex]=payload
      }
    },
    pushLanguageData (state,{payload}){
      state.languageData.push(payload)
    },

    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    }
  }
})

export const { setLanguageData, updateLanguageData,pushLanguageData, isOpenModal, 
  ModalToggle,setFaqsSpecializationData,isOpenStatusModal,statusToggle } = languageSlice.actions;

export default languageSlice.reducer;

export function getLanguage(limit, offset, status, keyword) {
  return async function getLanguageThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getLanguage(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setLanguageData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addLanguage(payload) {
  return async function addLanguageThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addLanguage(payload).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(pushLanguageData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateLanguage(id,name) {
  return async function updateLanguageThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateLanguage(id,name).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updateLanguageData(response.data))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdateLanguage(payload) {
  return async function statusUpdateLanguageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateLanguage(payload.id,payload.status).then(
        (response) => {
          dispatch(statusToggle())
          dispatch(updateLanguageData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

