import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  categoryData: [],
  totalCategory: 0,
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,
}

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategoryData(state, { payload }) {
      state.categoryData = payload.result
      state.totalCategory = payload.total
    },
    updateCategoryData(state, { payload }) {
      const objIndex = state.categoryData.findIndex((obj) => obj._id === payload._id);
      if (objIndex >= 0) {
        state.categoryData[objIndex] = payload
      }
    },
    pushCategoryData(state, { payload }) {
      state.categoryData.push(payload)
    },
    sliceCategoryData(state, { payload }) {
      const objIndex = state.categoryData.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.categoryData.splice(objIndex, 1)
      }
    },

    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  }
})

export const { setCategoryData, updateCategoryData, pushCategoryData, isOpenModal, ModalToggle, isOpenStatusModal, statusToggle, sliceCategoryData, ImagestatusToggle, isImageOpenModal } = categorySlice.actions;

export default categorySlice.reducer;

export function getCategory(limit, offset, status, keyword) {
  return async function getCategoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getCategory(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setCategoryData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addCategory(payload) {
  return async function addCategoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addCategory(payload).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(pushCategoryData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateCategory(id, name) {
  return async function updateCategoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateCategory(id, name).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updateCategoryData(response.data))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdateCategory(payload) {
  return async function statusUpdateCategoryThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateCategory(payload.id, payload.status).then(
        (response) => {
          dispatch(updateCategoryData(response.data))
          dispatch(statusToggle())
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function deleteCategory(id) {
  return async function deleteCategoryThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateCategory(id, 'deleted').then(
        (response) => {
          dispatch(sliceCategoryData(id))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function updateImageCategory(id, file) {

  return async function updateCategoryImageThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      dispatch(isImageOpenModal())
      await service.updateCategoryImage(id, file).then(
        (response) => {
          dispatch(updateCategoryData(response.data))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}