import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, infoHandler, successHandler } from '../shared/_helper/responseHelper';


const initialState = {
  staffData: [],
  totalStaff: 0,
  permissionData: [],
  staffDetails: null,
  staffList: [],
}

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setStaffData(state, { payload }) {
      state.staffData = payload.result
      state.totalStaff = payload.count
    },
    pushData(state, { payload }) {
      state.staffData.push(payload)
    },
    updateStaffDetails(state, { payload }) {
      const objIndex = state.staffData.findIndex((obj) => obj._id === payload._id);
      if (objIndex >= 0) {
        state.staffData[objIndex] = payload
      }
    },
    spliceStaffDetails(state, { payload }) {
      const objIndex = state.staffData.findIndex((obj) => obj._id === payload)
      if (objIndex >= 0) {
        state.staffData.splice(objIndex, 1)

      }
    },
  isOpenModal(state, { payload }) {
    state.isOpenModal = payload
  },
  ModalToggle(state, { payload }) {
    state.isOpenModal = !state.isOpenModal
  },
}
})

export const { setStaffData, isOpenModal, ModalToggle, pushData, updateStaffDetails, spliceStaffDetails } = staffSlice.actions;

export default staffSlice.reducer;

export function getStaff(limit, offset, status, role, keyword) {
  return async function getStaffThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getStaff(limit, offset, status, role, keyword).then(
        (response) => {

          if (response.data) {
            dispatch(setStaffData(response.data));
            dispatch(setLoading(false))
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function addStaff(body) {
  return async function addStaffThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addStaff(body).then(
        (response) => {
          if (response.data) {
            dispatch(setLoading(false))
            dispatch(isOpenModal(false))
            dispatch(pushData(response.data))
            successHandler('Added Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateStaff(id, data) {
  return async function updateStaffThunk(dispatch) {
    dispatch(isOpenModal(false))
    dispatch(setLoading(true))
    try {
      await service.updateStaff(id, data).then(
        (response) => {

          if (response.data) {
            dispatch(setLoading(false))
            dispatch(updateStaffDetails(response.data))
            successHandler('Updated Successfully')
          }
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function deleteStaff(id) {
  return async function deleteStaffThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.deleteStaff(id).then(
        (response) => {
          dispatch(spliceStaffDetails(id))
          dispatch(setLoading(false))
          infoHandler('Staff Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
