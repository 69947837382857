import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Dropzone from 'react-dropzone-uploader';
import { createBanner, DeleteBanner, getBannerList, isImageOpenModal, isOpenModal, ModalToggle, updateBannerImage, updateBannerType, } from '../../store/bannerSlice';
import SweetAlert from 'sweetalert2';


const BannerTable = () => {
    const storeVar = useSelector(state => state.banner)
    const dispatch = useDispatch();
    const toggle = () => dispatch(ModalToggle());
    const Imagetoggle = () => dispatch(isImageOpenModal());
    const [submit, setSubmit] = useState(false);
    const [formVar, setFormVar] = useState({
        modalTitle: null,
        editState: false,
        bannerFile: null,
        bannerImageURL: null,
        bannerId: '',
        type: 'top'
    });

    useEffect(() => {
        dispatch(getBannerList())
    }, []);

    const AddToggleModal = () => {
        dispatch(isOpenModal(true))
        setSubmit(false)
        setFormVar((prevFormVar) => ({
            ...prevFormVar,
            editState: false,
            modalTitle: 'Add Banner',
            type: 'top',
            bannerFile: null,
            bannerImageURL: null,

        }))
    }

    const DeleteModel = (data) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this imaginary file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                      dispatch(DeleteBanner(data._id,))
                }
            });
    }

    const EditToggleModal = (data) => {
        dispatch(isOpenModal(true))
        setFormVar((prevFormVar) => ({
            ...prevFormVar,
            editState: true,
            bannerId: data._id,
            type: data.type,
            modalTitle: 'Update Banner Type'
        }))
    }

    const ImageEditBannerModal = (data) => {
        dispatch(isImageOpenModal(true))
        setFormVar((prevFormVar) => ({
            ...prevFormVar,
            bannerId: data._id,
            modalTitle: 'Update Banner',
        }))
    }

    const submitImage = () => {
        if (filesValid()) {
            setSubmit(true)
            return null
        }
        setSubmit(false)
        dispatch(updateBannerImage(formVar.bannerId, formVar.bannerFile))
    }


    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => {
        if (status === 'done') {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFormVar((prevFormVar) => ({
                    ...prevFormVar,
                    bannerImageURL: e.target.result,
                }))
            };
            reader.readAsDataURL(file);
            setFormVar((prevFormVar) => ({
                ...prevFormVar,
                bannerFile: file,
            }))
        } else if (status === "removed") {
            setFormVar((prevFormVar) => ({
                ...prevFormVar,
                bannerFile: null,
                bannerImageURL: null,
            }))
        }
    };

    const filesValid = () => {
        if (!formVar.bannerFile) {
            return "Image is required";
        }
    }
    const submitData = () => {
        if (!formVar.editState)
            if (filesValid()) {
                setSubmit(true)
                return null
            }
        setSubmit(false)
        if (formVar.editState) {
            dispatch(updateBannerType(formVar.bannerId, formVar.type))
        }
        else {
            dispatch(createBanner(formVar.bannerFile, formVar.type))
        }
    }

    return (
        <Fragment>
            <Col sm='12'>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md="5">
                            </Col>
                            <Col md="4">

                            </Col>
                            <Col md="3" className='d-flex justify-content-end align-items-center'>
                                <div className="text-end border-2">
                                    <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                                        Add Banner
                                    </Btn>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                    <div className='table-responsive'>
                        <Table hover={true} className='table-border-horizontal table-light'>
                            <thead>
                                <tr>
                                    <th scope='col'>Sl.No</th>
                                    <th scope='col'>Image</th>
                                    <th scope='col'>Type</th>
                                    <th scope='col'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeVar.bannerData?.map((item, index) => (
                                    <tr key={item._id}>
                                        <th scope='row'>{index + 1}</th>
                                        <td className='w-25'>
                                            <img className='w-100 h-5-r' src={item.imageUrl} alt="" />
                                        </td>
                                        <th scope='row'>{item.type.toUpperCase()}</th>

                                        <td>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                                                    <i className="fa fa-edit fa-lg" onClick={(e) => EditToggleModal(item)} />
                                                    <div className="tooltipCustom">Update Banner Type</div>
                                                </div>
                                                <div className='cursor-pointer font-success action-icon'>
                                                    <i className="fa fa-picture-o fa-lg" onClick={(e) => ImageEditBannerModal(item)} />
                                                    <div className="tooltipCustom">Update Image</div>
                                                </div>
                                                <div className='cursor-pointer bg-light-info font-danger action-icon'>
                                                    <i className="fa fa-trash fa-lg" onClick={(e) => DeleteModel(item)}></i>
                                                    <div className="tooltipCustom">Delete</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Card>
            </Col>
            <CommonModal isOpen={storeVar.isImageOpenModal} title={formVar.modalTitle} toggler={Imagetoggle} >
                <Form>
                    {
                        formVar.bannerImageURL && <>
                            <div className='d-flex justify-content-center h-10-r'>
                                <img className=' h-100' src={formVar.bannerImageURL} alt="" />
                            </div>
                        </>
                    }

                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name">Image</Label>
                        <Dropzone
                            className='dropzone dz-clickable'
                            onChangeStatus={handleChangeStatus}
                            maxFiles={1}
                            multiple={false}
                            // canCancel={false}
                            accept="image/*"
                            inputContent='Drop A File'
                            styles={{
                                dropzone: { width: '100%', height: 150 },
                                dropzoneActive: { borderColor: 'green' },
                            }}
                        />
                        {submit && filesValid() ? <span className='d-block font-danger'>{filesValid()}</span> : ""}
                    </FormGroup>
                </Form>
                <ModalFooter>
                    <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
                    <Btn attrBtn={{ color: 'primary', onClick: submitImage }}>Save Changes</Btn>
                </ModalFooter>
            </CommonModal>
            <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
                <Form>
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name">Banner Type</Label>
                        <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                            value={formVar.type} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, type: e.target.value }))}>
                            <option value='top'>TOP</option>
                            <option value='middle'>MIDDLE</option>
                            <option value='shop'>SHOP</option>
                        </Input>
                        {!formVar.editState &&
                            <>
                                <Label className="col-form-label" for="recipient-name">Select Banner Image</Label>
                                <Dropzone
                                    className='dropzone dz-clickable'
                                    onChangeStatus={handleChangeStatus}
                                    maxFiles={1}
                                    multiple={false}
                                    // canCancel={false}
                                    accept="image/*"
                                    inputContent='Drop A File'
                                    styles={{
                                        dropzone: { width: '100%', height: 150 },
                                        dropzoneActive: { borderColor: 'green' },
                                    }}
                                />
                            </>
                        }
                        {submit && filesValid() ? <span className='d-block font-danger'>{filesValid()}</span> : ""}
                    </FormGroup>
                </Form>
                <ModalFooter>
                    <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
                    <Btn attrBtn={{ color: 'primary', onClick: submitData }}>Save Changes</Btn>
                </ModalFooter>
            </CommonModal>

        </Fragment>
    );
};

export default BannerTable;