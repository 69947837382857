import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import Widgets1 from '../../Common/CommonWidgets/Widgets1';
import { getAllAstrologer, getAllUser, getAllProducts, getAllCatagory, getAllOrder, getAllCoupons, getAllBlogs, getAllPodcasts, getAllSubCatagory } from '../../../store/dashboardSlice';
// import TodoContain from '../../Todo';
// import { useNavigate } from "react-router-dom";

const Social = () => {
  const storeVar = useSelector(state => state.dashboard)
  const dispatch = useDispatch();

  useEffect(() => {
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    // dispatch(getDashboardData())
    dispatch(getAllAstrologer())
    dispatch(getAllUser())
    dispatch(getAllProducts())
    dispatch(getAllCatagory())    
    dispatch(getAllOrder())    
    dispatch(getAllCoupons())    
    dispatch(getAllBlogs())    
    dispatch(getAllPodcasts())    
    dispatch(getAllSubCatagory())    
    
  }, [])

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Dashboard' parent='Dashboard' title='' />
      <Container fluid={true}>
        <Row>
          <Col xxl='12' xl='12' >
            <Row>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Total Astrologers' gros={70} total= {storeVar.astro} color='success' icon='movies' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Total Users' gros={50} total= {storeVar.user} color='secondary' icon='movies' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Total Queries' gros={70} total= {storeVar.catagory} color='primary' icon='languages' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Total Podcast' gros={70} total= {storeVar.podcasts} color='primary' icon='category' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Products' gros={20} total= {storeVar.products} color='warning' icon='languages' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Category' gros={70} total= {storeVar.catagory} color='primary' icon='category' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Sub-category' gros={50} total= {storeVar.subCatagory} color='secondary' icon='movies' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Total Orders' gros={70} total= {storeVar.orders} color='success' icon='movies' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Delivered Orders' gros={70} total= {storeVar.deliveredOrder} color='primary' icon='category' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Cancelled Orders' gros={20} total= {storeVar.failedOrder} color='warning' icon='languages' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Dispatched Orders' gros={70} total= {storeVar.dispatchedOrder} color='primary' icon='category' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Total Blogs' gros={70} total= {storeVar.blogs} color='primary' icon='category' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Total Coupons' gros={70} total= {storeVar.coupons} color='primary' icon='category' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Astrologer Transactions' gros={50} total= 'N/A' color='secondary' icon='movies' />
            </Col>
            <Col sm='6' xl='3' lg='6' className='box-col-6'>
              <Widgets1 title='Order Transactions' gros={70} total= 'N/A' color='primary' icon='category' />
            </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Social;
