// import React, { useEffect, useState } from 'react';

// function Checkbox({ type = 'td', onChecked, item, setItem, checked }) {
//     const [isChecked, setIsChecked] = useState(checked);

//     useEffect(() => {
//         setIsChecked(checked); // Update internal state when prop changes
//     }, [checked]);

//     useEffect(() => {
//         onChecked && onChecked(isChecked, item);
//     }, [isChecked, onChecked, item]);

//     // const change = () => {
//     //     setIsChecked(prev => !prev);
//     // };

//     const change = () => {
//         setIsChecked(prev => {
//             const newCheckedState = !prev;
//             onChecked && onChecked(newCheckedState, item); // Call onChecked with the new state
//             return newCheckedState;
//         });
//     };



//     return (
//         <label className="checkboxs" style={{ display: 'flex', alignItems: 'center' }}>
//             <input
//                 type="checkbox"
//                 checked={isChecked}
//                 className="checkbox_animated border border-primary"
//                 onChange={change}
//                 style={{ marginRight: '8px' }} // Space between checkbox and label
//             />
//             {item}
//         </label>
//     );
// }

// export default Checkbox;


import React from 'react';

function Checkbox({ onChecked, item, checked }) {
    const change = () => {
        const newCheckedState = !checked; 
        onChecked && onChecked(newCheckedState, item); 
    };

    return (
        <label className="checkboxs" style={{ display: 'flex', alignItems: 'center' }}>
            <input
                type="checkbox"
                checked={checked}
                className="checkbox_animated border border-primary"
                onChange={change}
                style={{ marginRight: '8px' }}
            />
            {item}
        </label>
    );
}

export default Checkbox;

