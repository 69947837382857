import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  streamData: [],
  totalStream: 0,
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal:false,
  isImageViewOpenModal:false,
}

export const streamSlice = createSlice({
  name: "stream",
  initialState,
  reducers: {
    setStreamData(state, { payload }) {
      state.streamData = payload.result
      state.totalStream = payload.count
    },
    updateStreamData(state, { payload }) {
      const objIndex = state.streamData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.streamData[objIndex] = payload
      }
    },
    deleteStreamData(state, { payload }) {
      const objIndex = state.streamData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.streamData.splice(objIndex, 1)
      }
    },
    pushStreamData(state, { payload }) {
      state.streamData.unshift(payload)
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    isOpenImageModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    imageToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    },
    isOpenImageViewModal(state, { payload }) {
      state.isImageViewOpenModal = payload
    },
    imageViewToggle(state, { payload }) {
      state.isImageViewOpenModal = !state.isImageViewOpenModal
    }
  }
})

export const { setStreamData, updateStreamData, pushStreamData,deleteStreamData, isOpenModal, ModalToggle,isOpenStatusModal,
  statusToggle,isOpenImageModal, imageToggle,isOpenImageViewModal, imageViewToggle } = streamSlice.actions;

export default streamSlice.reducer;

export function getStream(limit, offset, status, keyword) {
  return async function getStreamThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getStream(limit, offset, status, keyword).then(
        (response) => {
          console.log(response.data);
          dispatch(setLoading(false))
          dispatch(setStreamData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addStream(payload) {
  return async function addStreamThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addStream(payload).then(
        (response) => {
          console.log(response.data);
          dispatch(setLoading(false))
          dispatch(pushStreamData(response.data))
          dispatch(isOpenModal(false))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateStream(payload) {
  return async function updateStreamThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updateStream(payload.id, payload).then(
        (response) => {
          dispatch(updateStreamData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('updateed Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function statusUpdateStream(payload) {
  return async function statusUpdateStreamThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateStream(payload.id, payload.status).then(
        (response) => {
          dispatch(updateStreamData(response.data))
          dispatch(setLoading(false))
          dispatch(isOpenStatusModal(false))
          successHandler('Updateed Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      ); 
    } catch (err) {

    }
  }
}
export function streamFileUpload(id,file) {
  return async function streamFileUploadThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.streamFileUpload(id,file).then(
        (response) => {
          console.log(response.data);
          dispatch(setLoading(false))
          dispatch(isOpenImageModal(false))
          dispatch(updateStreamData(response.data))
          successHandler('Uploaded Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function deleteStreams(id) {
  console.log(id);
  return async function deleteStreamThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.deleteStream(id).then(
        (response) => {
          console.log(response.data);
          response.data['id']=id
          dispatch(setLoading(false))
          dispatch(deleteStreamData(response.data))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
