import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import OrdersTable from './OrdersTable';
const Orders = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Orders' parent='Orders'  />
      <Container fluid={true}>
        <Row>
          <OrdersTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Orders;
