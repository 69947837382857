
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  productKeywordData: [],

};

export const productKeywordSlice = createSlice({
  name: 'productKeyword',
  initialState,
  reducers: {
    setKeywords(state, { payload }) {
      console.log(payload);

      state.productKeywordData = payload.keywords;

    },

    sliceImageData(state, { payload }) {
      const objIndex = state.productImage.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.productImage.splice(objIndex, 1)
      }
    },

    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
  },
});

export const { setKeywords, sliceImageData, ModalToggle, isOpenModal } = productKeywordSlice.actions;
export default productKeywordSlice.reducer;

export function getproductKeyword(id) {
  return async function getproductKeywordThunk(dispatch, getState) {
    try {
      dispatch(setLoading(true))
      await service.getproductKeywordData(id,).then(
        (response) => {
          dispatch(setKeywords(response.data));
          dispatch(setLoading(false))
        }, (error) => {
        }
      );

    } catch (err) {
      console.log(err);

    }
  }
}

export function addproductKeyword(data) {
  return async function addproductKeywordThunk(dispatch, getState) {
    try {
      dispatch(setLoading(true))
      await service.addproductKeyword(data).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(getproductKeyword(data.id));
          dispatch(setLoading(false))
          successHandler('Added Successfully')

        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}

export function statusDeleteKeyword(data) {
  return async function statusDeleteBrandsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.deleteProductKeyword(data).then(
        (response) => {
          dispatch(getproductKeyword(data.id));
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}




