// dashbaord
import Social from "../Components/Dashboard/Social";
import ErrorPage4 from '../Components/Pages/ErrorPages/ErrorPage404';
import Answer from "../Pages/Answers";
import Astrologers from "../Pages/Astrologers";
import Banner from "../Pages/Banners";
import Blog from "../Pages/Blogs";
import Category from "../Pages/Category";
import Exam from "../Pages/Exam";
import Language from "../Pages/Language";
import Podcast from "../Pages/Podcast";
import Products from "../Pages/Products";
import ProductImages from "../Pages/Products/ProductImages";
import Query from "../Pages/Query";
import Question from "../Pages/Questions";
import Specialization from "../Pages/Specialization";
import Stream from "../Pages/Stream";
import SubCategory from "../Pages/SubCategory";
import Coupons from "../Pages/Coupons";
import Orders from "../Pages/Orders";
import OrdersDetails from "../Pages/Orders/OrderDetails";
import Users from "../Pages/Users";
import TransactionHistory from "../Pages/TransactionHistory";
import AstroDetails from "../Pages/AstroloDetails";
import Staff from "../Pages/Staff";
import ExamDetails from "../Pages/Astrologers/ExamDetails";
import CallDetails from "../Pages/CallDetails";
import VideoCallDetails from "../Pages/VideoCallDetails";
import ProductKeyword from "../Pages/Products/ProductKeywords";


export const routes = [
  { path: `/dashboard/:layout`, Component: <Social /> },
  { path: `/astrologers/:layout`, Component: <Astrologers /> },
  { path: `/astrologers/astroDetails/:layout`, Component: <AstroDetails /> },
  { path: `/astrologers/examDetails/:layout`, Component: <ExamDetails /> },
  { path: `/specialization/:layout`, Component: <Specialization /> },
  { path: `/category/:layout`, Component: <Category /> },
  { path: `/subcat/:layout`, Component: <SubCategory /> },
  { path: `/products/:layout`, Component: <Products /> },
  { path: `/products/productImages/:layout`, Component: <ProductImages /> },
  { path: `/products/keywords/:layout`, Component: <ProductKeyword /> },
  
  { path: `/exams/:layout`, Component: <Exam /> },
  { path: `/question/:layout`, Component: <Question /> },
  { path: `/exams/question/answer/:layout`, Component: <Answer /> },

  { path: `/blogs/:layout`, Component: <Blog /> },
  { path: `/banners/:layout`, Component: <Banner /> },
  { path: `/coupons/:layout`, Component: <Coupons /> },
  { path: `/podcast/:layout`, Component: <Podcast /> },
  { path: `/query/:layout`, Component: <Query /> },
  { path: `/users/:layout`, Component: <Users /> },
  { path: `/producttransactions/:layout`, Component: <TransactionHistory />},
  { path: `/orders/:layout`, Component: <Orders />},
  { path: `/orders-details/:layout`, Component: <OrdersDetails />},


  { path: `/stream/:layout`, Component: <Stream /> },
  { path: `/language/:layout`, Component: <Language /> },
  { path: `/staff/:layout`, Component: <Staff /> },
  { path: `/audiocalldetails/:layout`, Component: <CallDetails /> },
  { path: `/videocalldetails/:layout`, Component: <VideoCallDetails /> },


  //Error
  { path: `*`, Component: <ErrorPage4 /> },

];
