import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import SweetAlert from 'sweetalert2';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE, 
  bannerData: [],
  bannerDataCopy: [],
  isOpenModal: false,
  isStatusOpenModal:false,
  isImageOpenModal: false,

}

export const BannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setBannerData(state, { payload }) {
      state.bannerData = payload      
    },
    addBannerData(state, { payload }) {
      state.bannerData.push(payload)
    },
    updatebannerData(state, { payload }) {      
      const objIndex = state.bannerData.findIndex((obj) => obj._id === payload._id);
        state.bannerData[objIndex]=payload
    },
    sliceBannerData(state, { payload }) {    
      const objIndex = state.bannerData.findIndex((obj) => obj._id === payload);      
      if (objIndex >= 0) {
        state.bannerData.splice(objIndex, 1)
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  }
})

export const { setBannerData, addBannerData, setBannerCopyData, updatebannerData, isOpenModal, ModalToggle,isImageOpenModal, ImagestatusToggle, sliceBannerData } = BannerSlice.actions;

export default BannerSlice.reducer;

export function getBannerList() {
  return async function getBannerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getBanner().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setBannerData(response.data))
        
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function createBanner(file, type) {
  return async function createBannerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.createBannerImage(file, type).then(
        (response) => {
          dispatch(addBannerData(response.data));
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          // console.log(error);
          errorHandler(error.response)
        }
      );
    } catch (err) {
    

    }
  }
}

export function updateBannerType(id, type) {
  return async function updateBannerTypeThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateBanner(id, type).then(
        (response) => {
          dispatch(updatebannerData(response.data));
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {
      // console.log(err);
      // errorHandler()

    }
  }
}
export function updateBannerImage(bannerId, file) {
  return async function updateBannerImageThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateBannerImage(bannerId, file).then(
        (response) => {
          dispatch(updatebannerData(response.data));
          dispatch(ImagestatusToggle())
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {
    }
  }
}

export function DeleteBanner(bannerId) {
  return async function DeleteBannerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.deleteBannerData(bannerId).then(
        (response) => {
          dispatch(sliceBannerData(bannerId));
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {
    }
  }
}







