import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import SweetAlert from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Pagination from '../../Components/Pagination/Pagination';
import {
  ModalToggle, addStream, deleteStreams, getStream, imageToggle, imageViewToggle, isOpenImageModal, isOpenImageViewModal,
  isOpenModal, isOpenStatusModal, statusToggle, statusUpdateStream, streamFileUpload, updateStream
} from '../../store/streamSlice';
import Empty from '../../Components/Empty/Index';
import moment from 'moment';
import Dropzone from 'react-dropzone-uploader';
import { getCategory } from '../../store/categorySlice';
import { getLanguage } from '../../store/languageSlice';

const StreamTable = () => {
  const storeVar = useSelector(state => state.stream)
  const categoryVar = useSelector(state => state.category)
  const languageVar = useSelector(state => state.language)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const statusModalToggle = () => dispatch(statusToggle());
  const imageModalToggle = () => dispatch(imageToggle());
  const imageModalViewToggle = () => dispatch(imageViewToggle());
  const [stateStatus, setStateStatus] = useState('ACTIVE');
  const [submit, setSubmit] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);
  const typingDelay = 800;
  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    currentPage: 1,
    status: 'ACTIVE',
    modalTitle: null,
    editState: false,
    videoLink: '',
    name: '',
    desc: '',
    languageId: '',
    categoryId: '',
    top: false,
    shortDesc: '',
    thumbnailFile: '',
    thumbnailURL: '',
    thumbnailImage: '',
    viewThumbnailURL: '',
  });

  useEffect(() => {
    dispatch(getStream(formVar.limit, formVar.offset, formVar.status, formVar.keyword))
    dispatch(getCategory(formVar.limit, formVar.offset, formVar.status, formVar.keyword))
    dispatch(getLanguage(formVar.limit, formVar.offset, formVar.status, formVar.keyword))
  }, []);

  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getStream(formVar.limit, offset, formVar.status, formVar.keyword))
  };
  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    searchWithDelay(e.target.value)
  }
  const searchWithDelay = (keyword) => {
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      dispatch(getStream(formVar.limit, formVar.offset, formVar.status, keyword))
    }, typingDelay);
    setTypingTimer(timer);
  };
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(getStream(formVar.limit, formVar.offset, e.target.value, formVar.keyword))
  };

  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      streamId: data.id,
      modalTitle: 'Edit Stream',
      videoLink: data.videoLink,
      name: data.name,
      desc: data.desc,
      languageId: data.languageId,
      categoryId: data.categoryId,
      top: data.top,
    }))
  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Stream',
      videoLink: '',
      name: '',
      desc: '',
      languageId: '',
      categoryId: '',
      top: false,
    }))
  }
  const onValueChange = (event) => {
    setStateStatus(event.target.value)
  }
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      streamId: data.id,
    }))
  }
  const streamDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(deleteStreams(data.id))
        }
      });
  }
  const submitDegree = () => {
    if (videoLinkValid() || nameValid() ||
      categoryValid() ||
      languageValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.editState) {
      dispatch(updateStream({
        id: formVar.streamId,
        videoLink: formVar.videoLink,
        name: formVar.name,
        desc: formVar.desc,
        languageId: formVar.languageId,
        categoryId: formVar.categoryId,
        top: formVar.top,
      }))
    } else {
      dispatch(addStream({
        videoLink: formVar.videoLink,
        name: formVar.name,
        desc: formVar.desc,
        languageId: formVar.languageId,
        categoryId: formVar.categoryId,
        top: formVar.top,
      }))
    }
  }
  const submitStatus = () => {
    dispatch(statusUpdateStream({ id: formVar.streamId, status: stateStatus }))
  }
  const getDateTime = (date) => {
    if (date) {
      return moment(date).format('MMM Do YYYY hh:mm A')
    }
  }

  const imageViewToggleModal = (image) => {
    if (image) {
      dispatch(isOpenImageViewModal(true))
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        viewThumbnailURL: image,
      }))
    }
  }
  const imageToggleModal = (data) => {
    dispatch(isOpenImageModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      streamId: data.id,
      thumbnailImage: data.image,
      thumbnailURL: null,
    }))
  }
  const handleChangeFile = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          thumbnailURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        thumbnailFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        thumbnailFile: null,
        thumbnailURL: null,
      }))
    }
  };
  const submitBolg = () => {
    if (filesValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    dispatch(streamFileUpload(formVar.streamId, formVar.thumbnailFile))
  }
  const filesValid = () => {
    if (!formVar.thumbnailFile) {
      return "Files is required";
    }
  }
  const videoLinkValid = () => {
    if (!formVar.videoLink) {
      return "Title is required";
    }
  }
  const nameValid = () => {
    if (!formVar.name) {
      return "Title is required";
    }
  }
  const categoryValid = () => {
    if (!formVar.categoryId) {
      return "Category is required";
    }
  }
  const languageValid = () => {
    if (!formVar.languageId) {
      return "Language is required";
    }
  }

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="6">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='ACTIVE'>ACTIVE</option>
                  <option value='DEACTIVE'>DEACTIVE</option>
                  <option value='PENDING'>PENDING</option>
                </Input>
              </Col>
              <Col md="2" className='d-flex justify-content-end align-items-center'>
                <div className="text-end border-2 w-100">
                  <Btn attrBtn={{ color: 'info-gradien', className: 'w-100', size: 'sm', onClick: AddToggleModal }}>
                    Add Stream
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Image</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Video</th>
                  <th scope='col'>Top</th>
                  <th scope='col'>Created At</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.streamData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>
                      <div className="w-6-r cursor-pointer" onClick={() => imageViewToggleModal(item.image)}>
                        <img className='w-100 h-3-r' src={item.image} alt="img" />
                      </div>
                    </td>
                    <td>{item.name}</td>
                    <td>{item.videoLink}</td>
                    <td>{item.top?'Yes':'No'}</td>
                    <td>{getDateTime(item.createdAt)}</td>
                    <td>
                      {
                        item.status === 'ACTIVE' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'ACTIVE' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status}</span>
                          </span>
                        </>
                      }
                      {
                        item.status === 'PENDING' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'PENDING' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status}</span>
                          </span>
                        </>
                      }
                      {
                        item.status === 'DEACTIVE' && <>
                          <span className={`font-danger w-50 rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'DEACTIVE' && (
                              <i className="fa fa-times-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status}</span>
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2 align-items-center'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-edit fa-lg" onClick={(e) => EditToggleModal(item)}></i>
                          {/* <Edit onClick={(e) => EditToggleModal(item)} /> */}
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer bg-light-info font-info action-icon'>
                          <i className="fa fa-toggle-on fa-lg" onClick={(e) => statusToggleModal(item)}></i>
                          <div className="tooltipCustom">Status Update</div>
                        </div>
                        <div className='cursor-pointer bg-light-success font-success action-icon'>
                          <i className="fa fa-solid fa-image fa-lg" onClick={(e) => imageToggleModal(item)}></i>
                          {/* <i class="fa fa-solid fa-upload fa-lg"></i> */}
                          <div className="tooltipCustom">Image Upload</div>
                        </div>
                        <div className='cursor-pointer bg-light-danger font-danger action-icon'>
                          <i className="fa fa-trash fa-lg" aria-hidden="true" onClick={(e) => streamDelete(item)}></i>
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

          </div>
          {
            storeVar.streamData?.length <= 0 && (
              <Empty />
            )
          }
        </Card>
        {
          storeVar.streamData.length > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalStream}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} size='lg' title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Row>
              <Col md='6' sm='12'>
                <Label className="bold-form-label" for="recipient-name">Name</Label>
                <Input className="form-control" type="text" placeholder='Enter Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
                {submit && nameValid() ? <span className='d-block font-danger'>{nameValid()}</span> : ""}
              </Col>
              <Col md='6' sm='12'>
                <Label className="bold-form-label" for="recipient-name">Video Link</Label>
                <Input className="form-control" type="text" placeholder='Enter Video Link' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, videoLink: e.target.value }))} value={formVar.videoLink} />
                {submit && videoLinkValid() ? <span className='d-block font-danger'>{videoLinkValid()}</span> : ""}
              </Col>
              <Col md='6' sm='12'>
                <Label className="bold-form-label" for="recipient-name">Select Category</Label>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.categoryId} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, categoryId: e.target.value }))}>
                  <option value=''>Select Category</option>
                  {
                    categoryVar.categoryData?.map((item, index) => (
                      <option key={index} value={item.id}>{item.name}</option>
                    ))
                  }
                </Input>
                {submit && categoryValid() ? <span className='d-block font-danger'>{categoryValid()}</span> : ""}
              </Col>
              <Col md='6' sm='12'>
                <Label className="bold-form-label" for="recipient-name">Select Lenguage</Label>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.languageId} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, languageId: e.target.value }))}>
                  <option value=''>Select Lenguage</option>
                  {
                    languageVar.languageData?.map((item, index) => (
                      <option key={index} value={item.id}>{item.name}</option>
                    ))
                  }
                </Input>
                {submit && languageValid() ? <span className='d-block font-danger'>{languageValid()}</span> : ""}
              </Col>
              <Col md='6' sm='12'>
                <Label className="bold-form-label" for="recipient-name">Video Top</Label>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.top} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, top: e.target.value }))}>
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </Input>
              </Col>
            </Row>
            <Label className="bold-form-label" for="recipient-name">Description</Label>
            <Input className="form-control" type="textarea" rows='5' placeholder='Enter Video Description' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc: e.target.value }))} value={formVar.desc} />

          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitDegree }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isStatusOpenModal} title={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={stateStatus === 'ACTIVE'} onChange={onValueChange} value='ACTIVE' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline2' type='radio' className="radio_animated" name='radio2' checked={stateStatus === 'DEACTIVE'} onChange={onValueChange} value='DEACTIVE' />
              <Label className='mb-0' for='radioinline2'>
                <span className='digits'>DEACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={stateStatus === 'PENDING'} onChange={onValueChange} value='PENDING' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>PENDING</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isImageOpenModal} title="Upload File" toggler={imageModalToggle} >
        <Form>

          {
            formVar.thumbnailURL || formVar.thumbnailImage ? <>
              <div className='d-flex justify-content-center h-10-r bgLightBlack'>
                <img className=' h-100' src={formVar.thumbnailURL ? formVar.thumbnailURL : formVar.thumbnailImage} alt="" />
              </div>
            </>
              : ""
          }

          <FormGroup>
            <Label className="col-form-label" for="recipient-name">Image</Label>
            <Dropzone
              className='dropzone dz-clickable'
              onChangeStatus={handleChangeFile}
              maxFiles={1}
              multiple={false}
              // canCancel={false}
              accept="image/*"
              inputContent='Drop A File'
              styles={{
                dropzone: { width: '100%', height: 150 },
                dropzoneActive: { borderColor: 'green' },
              }}
            />
            {submit && filesValid() ? <span className='d-block font-danger'>{filesValid()}</span> : ""}
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: imageModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitBolg }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isImageViewOpenModal} title="Image" toggler={imageModalViewToggle} >
        <div className='w-100 h-100'>
          <img className='w-100 h-100' src={formVar.viewThumbnailURL} alt="img" />
        </div>
      </CommonModal>

    </Fragment>
  );
};

export default StreamTable;
