import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import SweetAlert from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import CustomizerContext from '../../_helper/Customizer';
import { addquestionData, ModalToggle, getquestion, isOpenModal, deleteQuestionData, } from '../../store/questionSlice';


const QuestionTable = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const storeVar = useSelector(state => state.question)
  const examId = new URLSearchParams(location.search).get('specializationId');
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const { layoutURL } = useContext(CustomizerContext);
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    modalTitle: null,
    editState: false,
    questionId: '',
    questionText: '',
    text1: '',
    isCorrect1: 'false',
    text2: '',
    isCorrect2: 'false',
    text3: '',
    isCorrect3: 'false',
    text4: '',
    isCorrect4: 'false'
  });

  useEffect(() => {
    dispatch(getquestion(examId))
  }, []);


  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Question',
      name: '',
      questionText: '',
      text1: '',
      isCorrect1: 'false',
      text2: '',
      isCorrect2: 'false',
      text3: '',
      isCorrect3: 'false',
      text4: '',
      isCorrect4: 'false'
    }))
    setSubmit(false);
  }

  const questionDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(deleteQuestionData(data._id, 'DELETED'))

        }
      });
  }
  const submitQuestion = () => {
    if (questionValid() || answer1Valid() || answer2Valid() || answer3Valid() || answer4Valid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)

    const data = {
      "specializationId": examId,
      "questionText": formVar.questionText,
      "answers": [
        { "text": formVar.text1, "isCorrect": formVar.isCorrect1 },
        { "text": formVar.text2, "isCorrect": formVar.isCorrect2 },
        { "text": formVar.text3, "isCorrect": formVar.isCorrect3 },
        { "text": formVar.text4, "isCorrect": formVar.isCorrect4 }
      ]
    }
    dispatch(addquestionData(data))
  }


  const questionValid = () => {
    if (!formVar.questionText) {
      return "Question is required";
    }
  }
  const answer1Valid = () => {
    if (!formVar.text1) {
      return "Answer 1 is required";
    }
  }
  const answer2Valid = () => {
    if (!formVar.text2) {
      return "Answer 2 is required";
    }
  }
  const answer3Valid = () => {
    if (!formVar.text3) {
      return "Answer 3 is required";
    }
  }
  const answer4Valid = () => {
    if (!formVar.text4) {
      return "Answer 4 is required";
    }
  }

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
              </Col>
              <Col md="4">
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>
                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add Question
                  </Btn>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Question</th>
                  <th scope='col'>Answer</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.questionData?.map((item, index) => (
                  <tr key={index}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.questionText}</td>
                    <td>{item.answers &&
                      item.answers.map((data) => (
                        <div key={data._id}>{data.text} {data.isCorrect ? "True" : "False"}</div>
                      ))}
                    </td>
                    <td>
                      <div className='d-flex gap-2 align-items-center'>
                        <div className='cursor-pointer font-danger action-icon'>
                          <i className="fa fa-trash fa-lg" aria-hidden="true" onClick={(e) => questionDelete(item)}></i>
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="recipient-name">Question</Label>
            <Input className="form-control" type="text" placeholder='Enter Question '
              onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, questionText: e.target.value }))} value={formVar.questionText}
            />
            {submit && questionValid() ? <span className='d-block font-danger'>{questionValid()}</span> : ""}
            <Row>
              <Label className="col-form-label" for="recipient-name">Answer 1</Label>
              <Col>
                <Input className="form-control" type="text" placeholder='Enter Answer 1 '
                  onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, text1: e.target.value }))} value={formVar.text1}
                />
                {submit && answer1Valid() ? <span className='d-block font-danger'>{answer1Valid()}</span> : ""}
              </Col>
              <Col>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.isCorrect1} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, isCorrect1: e.target.value }))}>
                  <option value='false'>FALSE</option>
                  <option value='true'>TRUE</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Label className="col-form-label" for="recipient-name">Answer 2</Label>
              <Col>
                <Input className="form-control" type="text" placeholder='Enter Answer 2 '
                  onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, text2: e.target.value }))} value={formVar.text2}
                />
                {submit && answer2Valid() ? <span className='d-block font-danger'>{answer2Valid()}</span> : ""}
              </Col>
              <Col>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.isCorrect2} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, isCorrect2: e.target.value }))}>
                  <option value='false'>FALSE</option>
                  <option value='true'>TRUE</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Label className="col-form-label" for="recipient-name">Answer 3</Label>
              <Col>
                <Input className="form-control" type="text" placeholder='Enter Answer 3 '
                  onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, text3: e.target.value }))} value={formVar.text3}
                />
                {submit && answer3Valid() ? <span className='d-block font-danger'>{answer3Valid()}</span> : ""}
              </Col>
              <Col>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.isCorrect3} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, isCorrect3: e.target.value }))}>
                  <option value='false'>FALSE</option>
                  <option value='true'>TRUE</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Label className="col-form-label" for="recipient-name">Answer 4</Label>
              <Col>
                <Input className="form-control" type="text" placeholder='Enter Answer 4 '
                  onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, text4: e.target.value }))} value={formVar.text4}
                />
                {submit && answer4Valid() ? <span className='d-block font-danger'>{answer4Valid()}</span> : ""}
              </Col>
              <Col>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.isCorrect4} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, isCorrect4: e.target.value }))}>
                  <option value='false'>FALSE</option>
                  <option value='true'>TRUE</option>
                </Input>
              </Col>
            </Row>
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitQuestion }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>

    </Fragment>
  );
};

export default QuestionTable;