import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import CouponTable from './CouponsTable';
const Coupons = () => {
  return (
    <Fragment>
      <Breadcrumbs parent='Coupon' mainTitle='Coupon' />
      <Container fluid={true}>
        <Row>
          <CouponTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Coupons;