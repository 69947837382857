import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import AnswerTable from './AnswerTable';
const Answer = () => {
  return (
    <Fragment>
      <Breadcrumbs  mainTitle='Answer' parent='Exam' subParent='Question' subParent2='Answer'/>
      <Container fluid={true}>
        <Row>
          <AnswerTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Answer;