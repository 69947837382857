import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  usersData: [],
  isOpenModal: false,
  isStatusOpenModal:false,
}

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setusersData(state, { payload }) {
      state.usersData = payload
    },
    updateusersData(state, { payload }) {      
      const objIndex = state.usersData.findIndex((obj) => obj._id === payload._id);
      if(objIndex>=0){
        state.usersData[objIndex]=payload
      }
    },
    pushusersData (state,{payload}){
      state.usersData.push(payload)
    },
    sliceusersData(state, { payload }) {
      const objIndex = state.usersData.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.usersData.splice(objIndex, 1)
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
  }
})

export const { setusersData, updateusersData, pushusersData, sliceusersData, isOpenModal, ModalToggle } = usersSlice.actions;

export default usersSlice.reducer;

export function getusers() {
  return async function getusersThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getAllUser().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setusersData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addusers(payload) {
  return async function addusersThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addusers(payload).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(pushusersData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updateusers(id,name) {
  return async function updateusersThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateusers(id,name).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updateusersData(response.data))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function deleteusers(id) {
  return async function deleteusersThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.deleteusers(id).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(sliceusersData(id))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}