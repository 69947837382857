import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Trash2, Edit, Image, FileText } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Dropzone from 'react-dropzone-uploader';
import NoImage from '../../assets/images/noimage.png';
import { fetchpodcasts, isOpenModal, isOpenStatusModal, ModalToggle, isImageOpenModal, statusUpdatepodcastStatus, addpodcasts, updatepodcasts, updatePodcast, updateImagepodcasts, DeletePodcast } from '../../store/podcastSlice';
import SweetAlert from 'sweetalert2';
import moment from 'moment';
import Empty from '../../Components/Empty/Index';
import { DeleteQuery, fetchQuery } from '../../store/querySlice';
import Pagination from '../../Components/Pagination/Pagination';

const QueryTable = () => {
  const storeVar = useSelector(state => state.query)
  console.log(storeVar);

  const dispatch = useDispatch();
  const [formVar, setFormVar] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  })

  useEffect(() => {
    dispatch(fetchQuery(formVar.limit, formVar.offset));
  }, []);



  const QueryDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(DeleteQuery(data._id))
        }
      });
  }

  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(fetchQuery(formVar.limit, offset,))
  };

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Email</th>
                  <th scope='col'>Type</th>
                  <th scope='col'>Query</th>
                  <th scope='col'>Description</th>
                  <th scope='col'>Created At</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.queryData?.map((item, index) => (
                  <tr key={item._id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.userId?.name}</td>
                    <td>{item.userId?.email}</td>
                    <td>{item.userId?.type.toUpperCase()}</td>
                    <td>{item.query}</td>
                    <td>{item.description}</td>
                    <td>{moment(item.createdAt).format('HH:mm Do MMM YYYY')}</td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => QueryDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
        {
          storeVar.queryData?.length <= 0 && (
            <Empty />
          )
        }
        {
          storeVar.queryData.length > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.queryCount}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>
    </Fragment>
  );
};

export default QueryTable;
