import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  transactionHistoryData: [],
  isOpenModal: false,
  isStatusOpenModal:false,
}

export const transactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState,
  reducers: {
    settransactionHistoryData(state, { payload }) {
      state.transactionHistoryData = payload
    },
    updatetransactionHistoryData(state, { payload }) {      
      const objIndex = state.transactionHistoryData.findIndex((obj) => obj._id === payload._id);
      if(objIndex>=0){
        state.transactionHistoryData[objIndex]=payload
      }
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    pushtransactionHistoryData (state,{payload}){
      state.transactionHistoryData.push(payload)
    },
    slicetransactionHistoryData(state, { payload }) {
      const objIndex = state.transactionHistoryData.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.transactionHistoryData.splice(objIndex, 1)
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
  }
})

export const { settransactionHistoryData, updatetransactionHistoryData,isOpenStatusModal, pushtransactionHistoryData, slicetransactionHistoryData, isOpenModal, ModalToggle, statusToggle } = transactionHistorySlice.actions;

export default transactionHistorySlice.reducer;

export function gettransactionHistory() {
  return async function gettransactionHistoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getAllTransaction().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(settransactionHistoryData(response.data.result))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addtransactionHistory(payload) {
  return async function addtransactionHistoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addtransactionHistory(payload).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(pushtransactionHistoryData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updatetransactionHistory(id,name) {
  return async function updatetransactionHistoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updatetransactionHistory(id,name).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updatetransactionHistoryData(response.data))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function deletetransactionHistory(id) {
  return async function deletetransactionHistoryThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.deletetransactionHistory(id).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(slicetransactionHistoryData(id))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}