import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Input, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../Components/Pagination/Pagination';
import Empty from '../../Components/Empty/Index';
import { getaudioCall } from '../../store/audioCallSlice';
import { getastrologer } from '../../store/astrologerSlice';
import moment from 'moment';


const CallDetailsTable = () => {
  const storeVar = useSelector(state => state.audioCall)
  const astroVar = useSelector(state => state.astrologer)
  const dispatch = useDispatch();
  const [formVar, setFormVar] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    astrologerId: '',
  });

  useEffect(() => {
    dispatch(getaudioCall(formVar.astrologerId, formVar.limit, formVar.offset))
    dispatch(getastrologer(1000, 0, 'active', ''))
  }, [dispatch]);

  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getaudioCall(formVar.astrologerId, formVar.limit, offset,))
  };

  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, astrologerId: e.target.value }))
    dispatch(getaudioCall(e.target.value, formVar.limit, formVar.offset))
  };

  const getDateTime = (date) => {
    if (date) {
      return moment(date).format('HH:mm DD/MM/YY')
    }
  }


  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="6">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.astrologerId} onChange={handleInputChange}
                >

                  <option value=''>ALL</option>
                  {astroVar.astrologerData?.map((item, index) => (
                    <option value={item.id} key={index}>{item.name}</option>
                  ))}
                </Input>

              </Col>
              <Col md="4">
              </Col>
              <Col md="2" className='d-flex justify-content-end align-items-center'>

              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>From</th>
                  <th scope='col'>To</th>
                  <th scope='col'>Duration in Seconds</th>
                  {/* <th scope='col'>Charges</th> */}
                  <th scope='col'>Call Time</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.data?.map((item, index) => (
                  <tr key={index}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.from.name}</td>
                    <td>{item.to.name}</td>
                    <td>{item.duration}</td>
                    {/* <td>{item.cost}</td> */}
                    <td>{getDateTime(item.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {
            storeVar.total <= 0 && (
              <Empty />
            )
          }
        </Card>
        {
          storeVar.total > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.total}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>

    </Fragment>
  );
};

export default CallDetailsTable;

