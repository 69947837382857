import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, CardBody, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Trash2, Edit, Image, FileText } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Dropzone from 'react-dropzone-uploader';
import NoImage from '../../assets/images/noimage.png';
import { fetchpodcasts, isOpenModal, isOpenStatusModal, ModalToggle, isImageOpenModal, statusUpdatepodcastStatus, addpodcasts, updatepodcasts, updatePodcast, updateImagepodcasts, DeletePodcast } from '../../store/podcastSlice';
import SweetAlert from 'sweetalert2';

import Empty from '../../Components/Empty/Index';

const PodcastTable = () => {
  const storeVar = useSelector(state => state.podcast)
  console.log(storeVar);

  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const Imagetoggle = () => dispatch(isImageOpenModal());
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    modalTitle: null,
    editState: false,
    imageFile: null,
    imageImageURL: null,
    podcastId: '',
    title: '',
    url: '',

  });

  useEffect(() => {
    dispatch(fetchpodcasts());
  }, []);

  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Podcast',
      title: '',
      url: '',
    }))
  }
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      modalTitle: 'Edit Podcast',
      podcastId: data._id,
      title: data.title,
      url: data.url,
    }))
  }

  const submitPodcast = () => {
    if (titleValid() || urlValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.editState) {
      dispatch(updatePodcast(formVar.podcastId, { title: formVar.title, url: formVar.url }))
    } else {
      dispatch(addpodcasts({ title: formVar.title, url: formVar.url }))
    }
  }
  const titleValid = () => {
    if (!formVar.title) {
      return "Title is required";
    }
  }
  const urlValid = () => {
    if (!formVar.url) {
      return "Url is required";
    }
  }

  const ImageEditBannerModal = (data) => {
    dispatch(isImageOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      podcastId: data._id,
      modalTitle: 'Update Image',
      imageFile: null,
      imageImageURL: null,
    }))
  }

  const BannerDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(DeletePodcast(data._id))
        }
      });
  }

  const submitImage = () => {
    if (filesValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    dispatch(updateImagepodcasts(formVar.podcastId, formVar.imageFile))
  }
  const filesValid = () => {
    if (!formVar.imageFile) {
      return "Files is required";
    }
  }

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          imageImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        imageFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        imageFile: null,
        imageImageURL: null,
      }))
    }
  };
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
              </Col>
              <Col md="4">
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>
                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add Podcast
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Title</th>
                  <th scope='col'>Url</th>
                  <th scope='col'>Image</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.podcastData?.map((item, index) => (
                  <tr key={item._id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.title}</td>
                    <td >{item.url}</td>
                    <td className='w-20'>
                      {item.image ? <img className='w-10-r h-5-r' src={item.image} alt="" /> : <img className='w-10-r h-5-r' src={NoImage} alt="" />}
                    </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <Edit onClick={(e) => EditToggleModal(item)} />
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer font-success action-icon'>
                          <Image onClick={(e) => ImageEditBannerModal(item)} />
                          <div className="tooltipCustom">Update Banner</div>
                        </div>
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => BannerDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
        {
          storeVar.podcastData?.length <= 0 && (
            <Empty />
          )
        }
      </Col>
      <CommonModal isOpen={storeVar.isImageOpenModal} title={formVar.modalTitle} toggler={Imagetoggle} >
        <Form>
          <FormGroup>
            {
              formVar.imageImageURL && <>
                <div className='d-flex justify-content-center h-10-r'>
                  <img className=' h-100' src={formVar.imageImageURL} alt="" />
                </div>
              </>
            }
            <Label className="col-form-label" for="recipient-name">Update Image</Label>
            <Dropzone
              className='dropzone dz-clickable'
              onChangeStatus={handleChangeStatus}
              maxFiles={1}
              multiple={false}
              // canCancel={false}
              accept="image/*"
              inputContent='Drop A File'
              styles={{
                dropzone: { width: '100%', height: 150 },
                dropzoneActive: { borderColor: 'green' },
              }}
            />
            {submit && filesValid() ? <span className='d-block font-danger'>{filesValid()}</span> : ""}
          </FormGroup>

        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: Imagetoggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitImage }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} size={"xl"}>
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="recipient-name">Title</Label>
            <Input className="form-control" type="text" placeholder='Enter Podcast Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, title: e.target.value }))} value={formVar.title} />
            {submit && titleValid() ? <span className='d-block font-danger'>{titleValid()}</span> : ""}
            <Label className="col-form-label" for="recipient-name">Url</Label>
            <Input className="form-control" type="text" placeholder='Enter Podcast Url' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, url: e.target.value }))} value={formVar.url} />
            {submit && urlValid() ? <span className='d-block font-danger'>{urlValid()}</span> : ""}
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitPodcast }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default PodcastTable;
