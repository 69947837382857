import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Empty from '../../Components/Empty/Index';
import SweetAlert from 'sweetalert2';
import { gettransactionHistory, ModalToggle, isOpenStatusModal } from '../../store/transactionHistorySlice';

const TransactionHistoryTable = () => {
    const storeVar = useSelector(state => state.transactionHistory)

    const dispatch = useDispatch();
    const toggle = () => dispatch(ModalToggle());
    const [formVar, setFormVar] = useState({
        modalTitle: null,
        editState: false,
        userId: '',
        orderId: '',
        cartId: '',
        status: '',
    });

    useEffect(() => {
        dispatch(gettransactionHistory())
    }, []);

    return (
        <Fragment>
            <Col sm='12'>
                <Card>
                    <div className='table-responsive'>
                        <Table hover={true} className='table-border-horizontal table-light'>
                            <thead>
                                <tr>
                                    <th scope='col'>Sl.No</th>
                                    <th scope='col'>User Id</th>
                                    <th scope='col'>Order Id</th>
                                    <th scope='col'>Cart Id</th>
                                    <th scope='col'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeVar.transactionHistoryData?.map((item, index) => (
                                    <tr key={item._id}>
                                        <th scope='row'>{index + 1}</th>
                                        <td>{item.userId}</td>
                                        <td>{item.orderId}</td>
                                        <td>{item.cartId}</td>
                                        <td>
                                            {
                                                item.status === 'success' && <>
                                                    <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                                                        {item.status === 'success' && (
                                                            <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                                                        )}
                                                        &nbsp; <span>{item.status.toUpperCase()}</span>
                                                    </span>
                                                </>
                                            }
                                            {
                                                item.status === 'failed' && <>
                                                    <span className={`font-failed rounded-1 p-1 me-2 d-flex align-items-center`}>
                                                        {item.status === 'failed' && (
                                                            <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                                                        )}
                                                        &nbsp; <span>{item.status.toUpperCase()}</span>
                                                    </span>
                                                </>
                                            }
                                            {
                                                item.status === 'pending' && <>
                                                    <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                                                        {item.status === 'pending' && (
                                                            <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                                                        )}
                                                        &nbsp; <span>{item.status.toUpperCase()}</span>
                                                    </span>
                                                </>
                                            }
                                            {
                                                item.status === 'refunded' && <>
                                                    <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                                                        {item.status === 'refunded' && (
                                                            <i className="fa fa-money-bill fa-lg" aria-hidden="true"></i>
                                                        )}
                                                        &nbsp; <span>{item.status.toUpperCase()}</span>
                                                    </span>
                                                </>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    {
                        storeVar.transactionHistoryData.length <= 0 && (
                            <Empty />
                        )
                    }
                </Card>
            </Col>

        </Fragment>
    );
};

export default TransactionHistoryTable;
