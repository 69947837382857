import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import CategoryTable from './AstrologersTable';
const Astrologers = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Astrologers' parent='Astrologers'  />
      <Container fluid={true}>
        <Row>
          <CategoryTable />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Astrologers;
