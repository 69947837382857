import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { useLocation } from 'react-router-dom';
import {  Trash2, } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../../Components/Modals/modal';
import Dropzone from 'react-dropzone-uploader';

import { fetchProductImage, addProductImage, statusDeleteProduct,  isImageOpenModal, ImagestatusToggle } from '../../../store/productImageSlice';
import SweetAlert from 'sweetalert2';

const ProductImages = () => {
  const storeVar = useSelector(state => state.productImage)
  const dispatch = useDispatch();
  const location = useLocation();
  const Imagetoggle = () => dispatch(ImagestatusToggle());
  const [submit, setSubmit] = useState(false);

  const [productId, setProductId] = useState("")
  const [formVar, setFormVar] = useState({
    modalTitle: null,
    editState: false,
    bannerFile: null,
    bannerImageURL: null,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setProductId(searchParams.get('id'));
    dispatch(fetchProductImage(searchParams.get('id'),));
  }, [location.search, dispatch]);

  const BannerDelete = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {

          dispatch(statusDeleteProduct(data._id))

        }
      });
  }

  const ImageAddModal = (data) => {
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      bannerImageURL: '',
      bannerFile: null,
      priority: 10
    }))
    dispatch(isImageOpenModal(true))
  }




  const submitImage = () => {
    if (filesValid()) {
      setSubmit(true)
      return null
    }

    setSubmit(false)
    dispatch(addProductImage(productId,  formVar.bannerFile))
  }
  const filesValid = () => {
    if (!formVar.bannerFile) {
      return "Files is required";
    }
  }



  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormVar((prevFormVar) => ({
          ...prevFormVar,
          bannerImageURL: e.target.result,
        }))
      };
      reader.readAsDataURL(file);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        bannerFile: file,
      }))
    } else if (status === "removed") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        bannerFile: null,
        bannerImageURL: null,
      }))
    }
  };


  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
              </Col>

              <Col md="4" className='d-flex justify-content-end align-items-center'>
                
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>
              
                {storeVar.productImage.length < 5   &&

                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: ImageAddModal }}>
                    Add Images
                  </Btn>
                </div>

                }
              
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Image</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar?.productImage?.map((item, index) => (
                  <tr key={index}>
                    <th scope='row'>{index + 1}</th>
                    <td className='w-25'>
                      {item.image && <img className='w-80 h-5-r' src={item.image} alt="" />}
                    </td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer font-danger action-icon '>
                          <Trash2 onClick={(e) => BannerDelete(item)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>
      <CommonModal isOpen={storeVar.isImageOpenModal} title={"Add Images"} toggler={Imagetoggle} >
        <Form>
          <FormGroup>
            {
              formVar.bannerImageURL && <>
                <div className='d-flex justify-content-center h-10-r'>
                  <img className=' h-100' src={formVar.bannerImageURL} alt="" />
                </div>
              </>
            }
            <Label className="col-form-label" for="recipient-name">Image</Label>
            <Dropzone
              className='dropzone dz-clickable'
              onChangeStatus={handleChangeStatus}
              maxFiles={1}
              multiple={false}
              // canCancel={false}
              accept="image/*"
              inputContent='Drop A File'
              styles={{
                dropzone: { width: '100%', height: 150 },
                dropzoneActive: { borderColor: 'green' },
              }}
            />
            {submit && filesValid() ? <span className='d-block font-danger'>{filesValid()}</span> : ""}
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: Imagetoggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitImage }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment>
  );
};

export default ProductImages;
