
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import SweetAlert from 'sweetalert2';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  isOpenModal: false,
  isStatusOpenModal: false,
  isImageOpenModal: false,
  productImage: [],

};

export const productImagesSlice = createSlice({
  name: 'productImages',
  initialState,
  reducers: {
    setImages(state, { payload }) {
      console.log(payload);

      state.productImage = payload.images;

    },
    
    pushImageData(state, { payload }) {
      state.productImage.push(payload.image)
    },
    sliceImageData(state, { payload }) {
      const objIndex = state.productImage.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.productImage.splice(objIndex, 1)
      }
    },

    isImageOpenModal(state, { payload }) {
      state.isImageOpenModal = payload
    },
    
    ImagestatusToggle(state, { payload }) {
      state.isImageOpenModal = !state.isImageOpenModal
    }
  },
});

export const { setImages,sliceImageData, pushImageData, isImageOpenModal, ImagestatusToggle } = productImagesSlice.actions;
export default productImagesSlice.reducer;

export function fetchProductImage(id) {
  return async function fetchProductImageThunk(dispatch, getState) {
    try {
      dispatch(setLoading(true))
      await service.productImageData(id,).then(
        (response) => {
          dispatch(setImages(response.data));
          dispatch(setLoading(false))
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}

export function addProductImage(id, file) {
  return async function addProductImageThunk(dispatch, getState) {
    try {
      dispatch(setLoading(true))
      await service.addproductImage(id, file).then(
        (response) => {
          dispatch(isImageOpenModal())
          dispatch(setLoading(false))
          successHandler('Added Successfully')
          dispatch(pushImageData(response.data));

        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}

export function statusDeleteProduct(id, status) {
  return async function statusDeleteBrandsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.deleteProductImage(id, status).then(
        (response) => {
          dispatch(sliceImageData(id));
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}




