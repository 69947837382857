import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  couponData: [],
  isOpenModal: false,
  isStatusOpenModal:false,
}

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setcouponData(state, { payload }) {
      state.couponData = payload
    },
    updatecouponData(state, { payload }) {      
      const objIndex = state.couponData.findIndex((obj) => obj._id === payload._id);
      if(objIndex>=0){
        state.couponData[objIndex]=payload
      }
    },
    pushcouponData (state,{payload}){
      state.couponData.push(payload)
    },
    slicecouponData(state, { payload }) {
      const objIndex = state.couponData.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.couponData.splice(objIndex, 1)
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
  }
})

export const { setcouponData, updatecouponData, pushcouponData, slicecouponData, isOpenModal, ModalToggle } = couponSlice.actions;

export default couponSlice.reducer;

export function getcoupon() {
  return async function getcouponThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getAllCoupons().then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setcouponData(response.data.coupons))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addcoupon(payload) {
  return async function addcouponThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addCoupon(payload).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(pushcouponData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updatecoupon(id,name) {
  return async function updatecouponThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateCoupon(id,name).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updatecouponData(response.data))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function deletecoupon(id) {
  return async function deletecouponThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.deleteCoupon(id).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(slicecouponData(id))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}