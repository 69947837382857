import React from 'react'
import emptyImg from '../../assets/images/no_data.jpg'

const Empty = () => {
    return (
        <div>
            <div className='empty-container'>
                <div className="img-container">
                    <img src={emptyImg} alt="" />
                    <div className='empty-title'>No Record Found</div>
                </div>
            </div>
        </div>
    )
}

export default Empty