// import { localService } from "../../shared/_session/local";

// const hasPermission = (permission) => {
//   const userType = localService.get("type");
//   const permissions = localService.get("permissions") || [];
//   return (
//     userType === "admin" ||
//     (userType === "staff" && permissions.includes(permission))
//   );
// };

// export const MENUITEMS = [
//   {
//     menutitle: "Dashboard",
//     menucontent: "Dashboards",
//     Items: [
//       {
//         path: `/dashboard`,
//         icon: "home",
//         activeTitle: "Dashboard",
//         title: "Dashboard",
//         type: "link",
//         show: true,
//       },
//     ],
//   },
//   {
//     menutitle: "General",
//     menucontent: "Dashboards,Widgets",
//     Items: [
//       {
//         path: `/specialization`,
//         icon: "specialization",
//         activeTitle: "specialization",
//         title: "Specialization",
//         type: "link",
//         show: hasPermission("Specialization"),
//         permission: "Specialization",
//       },
//       {
//         path: `/staff`,
//         icon: "specialization",
//         activeTitle: "staff",
//         title: "Staff",
//         type: "link",
//         show: hasPermission("Staff"),
//       },
//       // {
//       //   path: `/exams`,
//       //   icon: "blogs",
//       //   activeTitle: "exams",
//       //   title: "Exam",
//       //   type: "link",
//       //   show: hasPermission("Exam"),
//       // },
//       {
//         path: `/astrologers`,
//         icon: "specialization",
//         activeTitle: "astrologers",
//         title: "Astrologers",
//         type: "link",
//         show: hasPermission("Astrologers"),
//       },
//       {
//         path: `/category`,
//         icon: "blogs",
//         activeTitle: "category",
//         title: "Category",
//         type: "link",
//         show: hasPermission("Category"),
//       },
//       {
//         path: `/subcat`,
//         icon: "blogs",
//         activeTitle: "subcat",
//         title: "Sub Category",
//         type: "link",
//         show: hasPermission("Subcategory"),
//       },
//       {
//         path: `/products`,
//         icon: "blogs",
//         activeTitle: "products",
//         title: "Products",
//         type: "link",
//         show: hasPermission("Products"),
//       },
//       {
//         path: `/blogs`,
//         icon: "blogs",
//         activeTitle: "blogs",
//         title: "Blogs",
//         type: "link",
//         show: hasPermission("Blogs"),
//       },
//       {
//         path: `/podcast`,
//         icon: "blogs",
//         activeTitle: "podcast",
//         title: "Podcast",
//         type: "link",
//         show: hasPermission("Podcast"),
//       },
//       {
//         path: `/banners`,
//         icon: "blogs",
//         activeTitle: "banners",
//         title: "Banners",
//         type: "link",
//         show: hasPermission("Banners"),
//       },
//       {
//         path: `/coupons`,
//         icon: "specialization",
//         activeTitle: "coupons",
//         title: "Coupons",
//         type: "link",
//         show: hasPermission("Coupons"),
//       },
//       {
//         path: `/orders`,
//         icon: "blogs",
//         activeTitle: "orders",
//         title: "Orders",
//         type: "link",
//         show: hasPermission("Orders"),
//       },
//       {
//         path: `/producttransactions`,
//         icon: "blogs",
//         activeTitle: "producttransactions",
//         title: "ProductTransactions",
//         type: "link",
//         show: hasPermission("Transactions"),
//       },
//       {
//         path: `/users`,
//         icon: "blogs",
//         activeTitle: "users",
//         title: "Users",
//         type: "link",
//         show: hasPermission("Users"),
//       },
//       {
//         path: `/query`,
//         icon: "blogs",
//         activeTitle: "query",
//         title: "Query",
//         type: "link",
//         show: hasPermission("Query"),
//       },
//       {
//         path: `/audiocalldetails`,
//         icon: "blogs",
//         activeTitle: "audiocalldetails",
//         title: "AudioCallDetails",
//         type: "link",
//         show: true,
//       },
//       {
//         path: `/videocalldetails`,
//         icon: "blogs",
//         activeTitle: "videocalldetails",
//         title: "VideoCallDetails",
//         type: "link",
//         show: true,
//       },
//     ],
//   },
// ];



import { localService } from "../../shared/_session/local";

const hasPermission = (permission) => {
  const userType = localService.get("type");
  const permissions = localService.get("permissions") || [];
  return (
    userType === "admin" ||
    (userType === "staff" && permissions.includes(permission))
  );
};

export const MENUITEMS = [
  {
    menutitle: "Dashboard",
    menucontent: "Dashboards",
    Items: [
      {
        path: `/dashboard`,
        icon: "home",
        activeTitle: "Dashboard",
        title: "Dashboard",
        type: "link",
        show: true,
      },
    ],
  },
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        path: `/specialization`,
        icon: "specialization",
        activeTitle: "specialization",
        title: "Specialization",
        type: "link",
        show: true,
        permission: "Specialization",
      },
      {
        path: `/staff`,
        icon: "specialization",
        activeTitle: "staff",
        title: "Staff",
        type: "link",
        show: true,
        permission: "Staff",
      },
      // {
      //   path: `/exams`,
      //   icon: "blogs",
      //   activeTitle: "exams",
      //   title: "Exam",
      //   type: "link",
      //   show: hasPermission("Exam"),
      // },
      {
        path: `/astrologers`,
        icon: "specialization",
        activeTitle: "astrologers",
        title: "Astrologers",
        type: "link",
        show: true,
        permission: "Astrologers",
      },
      {
        path: `/category`,
        icon: "blogs",
        activeTitle: "category",
        title: "Category",
        type: "link",
        show: true,
        permission: "Category",
      },
      {
        path: `/subcat`,
        icon: "blogs",
        activeTitle: "subcat",
        title: "Sub Category",
        type: "link",
        show: true,
        permission: "Subcategory",
      },
      {
        path: `/products`,
        icon: "blogs",
        activeTitle: "products",
        title: "Products",
        type: "link",
        show: true,
        permission: "Products",
      },
      {
        path: `/blogs`,
        icon: "blogs",
        activeTitle: "blogs",
        title: "Blogs",
        type: "link",
        show: true,
        show: "Blogs",
      },
      {
        path: `/podcast`,
        icon: "blogs",
        activeTitle: "podcast",
        title: "Podcast",
        type: "link",
        show: true,
        permission: "Podcast",
      },
      {
        path: `/banners`,
        icon: "blogs",
        activeTitle: "banners",
        title: "Banners",
        type: "link",
        show: true, 
        permission: "Banners",
      },
      {
        path: `/coupons`,
        icon: "specialization",
        activeTitle: "coupons",
        title: "Coupons",
        type: "link",
        show: true,
        permission: "Coupons",
      },
      {
        path: `/orders`,
        icon: "blogs",
        activeTitle: "orders",
        title: "Orders",
        type: "link",
        show: true,
        permission: "Orders",
      },
      // {
      //   path: `/producttransactions`,
      //   icon: "blogs",
      //   activeTitle: "producttransactions",
      //   title: "ProductTransactions",
      //   type: "link",
      //   show: true,
      //   permission: "Transactions",
      // },
      {
        path: `/users`,
        icon: "blogs",
        activeTitle: "users",
        title: "Users",
        type: "link",
        show: true,
        permission: "Users",
      },
      {
        path: `/query`,
        icon: "blogs",
        activeTitle: "query",
        title: "Query",
        type: "link",
        show: true,
        permission: "Query",
      },
      {
        path: `/audiocalldetails`,
        icon: "blogs",
        activeTitle: "audiocalldetails",
        title: "AudioCallDetails",
        type: "link",
        show: true,
      },
      {
        path: `/videocalldetails`,
        icon: "blogs",
        activeTitle: "videocalldetails",
        title: "VideoCallDetails",
        type: "link",
        show: true,
      },
    ],
  },
];



