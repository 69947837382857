import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Empty from '../../Components/Empty/Index';
import SweetAlert from 'sweetalert2';
import { getcoupon, ModalToggle, isOpenModal, addcoupon, updatecoupon, deletecoupon, } from '../../store/couponSlice';
import moment from 'moment';


const CouponTable = () => {
    const storeVar = useSelector(state => state.coupon)
    const dispatch = useDispatch();
    const toggle = () => dispatch(ModalToggle());
    const [submit, setSubmit] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [formVar, setFormVar] = useState({
        modalTitle: null,
        editState: false,
        id: '',
        code: '',
        discountAmount: 0,
        minimumAmount: 0,
        updatedAt: '',
        validTo: new Date(),
        createdAt: '',
        title: '',
        validFrom: new Date(),
    });

    useEffect(() => {
        dispatch(getcoupon());
    }, []);

    const EditToggleModal = (data) => {
        dispatch(isOpenModal(true))
        setFormVar((prevFormVar) => ({
            ...prevFormVar,
            editState: true,
            id: data._id,
            modalTitle: 'Edit Coupon',
            title: data.title,
            code: data.code,
            discountAmount: data.discountAmount,
            minimumAmount: data.minimumAmount,
            updatedAt: data.updatedAt,
            validTo: data.validTo,
            createdAt: data.createdAt,
            validFrom: data.validFrom,
        }))
    }
    const AddToggleModal = () => {
        dispatch(isOpenModal(true))
        setFormVar((prevFormVar) => ({
            ...prevFormVar,
            editState: false,
            modalTitle: 'Add Coupon',
            id: '',
            code: '',
            discountAmount: '',
            minimumAmount: '',
            updatedAt: '',
            validTo: '',
            createdAt: '',
            title: '',
            validFrom: '',
        }))
    }

    const DeleteModel = (data) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover the data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    dispatch(deletecoupon(data._id,))
                }
            });
    }

    const submitMenu = () => {
        if (titleValidation() || codeValidation() || minimumValidation() || discountValidation() || todateValidation() || fromdateValidation()) {
            setSubmit(true)
            return null
          }
          setSubmit(false)
        if (formVar.editState) {
            dispatch(updatecoupon(formVar.id, { title: formVar.title , code: formVar.code, discountAmount: formVar.discountAmount, minimumAmount: formVar.minimumAmount, validTo: formVar.validTo, validFrom: formVar.validFrom}))
        } else {
            dispatch(addcoupon({ title: formVar.title , code: formVar.code, discountAmount: formVar.discountAmount, minimumAmount: formVar.minimumAmount, validTo: formVar.validTo, validFrom: formVar.validFrom}))
        }
    }

    const titleValidation = () => {
        if (!formVar.title) {
          return "Title is required";
        }
      }
    const codeValidation = () => {
        if (!formVar.code) {
            return "Code is required";
        }
    }
    const minimumValidation= () => {
        if (!formVar.minimumAmount) {
            return "Discount amount is required";
        }
    }
    const discountValidation = () => {
        if (!formVar.discountAmount) {
            return "Minimum Amount is required";
        }
    }
    const fromdateValidation =() => {
        if (!formVar.validFrom) {
            return "Valid date is required";
        }
    }
    const todateValidation =() => {
        if (!formVar.validTo) {
            return "Valid date is required";
        }
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString); 
        // const day = String(date.getDate()).padStart(2, '0'); 
        // const month = String(date.getMonth() + 1).padStart(2, '0');
        // const year = String(date.getFullYear()).slice(-2); 
        // return `${day}/${month}/${year}`;    
        return String(date.toLocaleDateString()); 
      };

    return (
        <Fragment>
            <Col sm='12'>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md="6">
                            </Col>
                            <Col md="4">
                            </Col>
                            <Col md="2" className='d-flex justify-content-end align-items-center'>
                                <div className="text-end border-2">
                                    <Btn attrBtn={{ color: 'info-gradien', className: 'w-100', size: 'sm', onClick: AddToggleModal }}>
                                        Add Coupon
                                    </Btn>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                    <div className='table-responsive'>
                        <Table hover={true} className='table-border-horizontal table-light'>
                            <thead>
                                <tr>
                                    <th scope='col'>Sl.No</th>
                                    <th scope='col'>Title</th>
                                    <th scope='col'>Code</th>
                                    <th scope='col'>Created At</th>
                                    <th scope='col'>Discount Amount</th>
                                    <th scope='col'>Minimum Amount</th>
                                    <th scope='col'>Valid From</th>
                                    <th scope='col'>Valid To</th>
                                    <th scope='col'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeVar.couponData?.map((item, index) => (
                                    <tr key={item._id}>
                                        <th scope='row'>{index + 1}</th>
                                        <td>{item.title}</td>
                                        <td>{item.code}</td>
                                        <td>{formatDate(item.createdAt)}</td>
                                        <td>{item.discountAmount}</td>
                                        <td>{item.minimumAmount}</td>
                                        <td>{formatDate(item.validFrom)}</td>
                                        <td>{formatDate(item.validTo)}</td>

                                        <td>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                                                    <i className="fa fa-edit fa-lg" onClick={(e) => EditToggleModal(item)}></i>
                                                    <div className="tooltipCustom">Edit</div>
                                                </div>
                                                <div className='cursor-pointer bg-light-info font-danger action-icon'>
                                                    <i className="fa fa-trash fa-lg" onClick={(e) => DeleteModel(item)}></i>
                                                    <div className="tooltipCustom">Delete</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    {
                        storeVar.couponData.length <= 0 && (
                            <Empty />
                        )
                    }
                </Card>
            </Col>
            <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} size={"lg"}>
                <Form>
                    <FormGroup>
                        <Label className="col-form-label" for="recipient-name">Title</Label>
                        <Input className="form-control" type="text" maxLength="50" placeholder='Enter coupon Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, title: e.target.value }))} value={formVar.title} />
                        {submit && titleValidation()? <span className='d-block font-danger'>{titleValidation()}</span> : ""}
                        <Label className="col-form-label" for="recipient-name">Code</Label>
                        <Input className="form-control" type="text" maxLength="10" placeholder='Enter coupon Code' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, code: e.target.value }))} value={formVar.code} />
                        {submit && codeValidation()? <span className='d-block font-danger'>{codeValidation()}</span> : ""}
                        <Label className="col-form-label" for="recipient-name">Minimum Amount</Label>
                        <Input className="form-control" type="text" maxLength="5" placeholder='Enter Minimum Amount' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, minimumAmount: e.target.value }))} value={formVar.minimumAmount} />
                        {submit && minimumValidation()? <span className='d-block font-danger'>{minimumValidation()}</span> : ""}
                        <Label className="col-form-label" for="recipient-name">Discount Amount</Label>
                        <Input className="form-control" type="text" maxLength="5" placeholder='Enter Discount Amount' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, discountAmount: e.target.value }))} value={formVar.discountAmount} />
                        {submit && discountValidation()? <span className='d-block font-danger'>{discountValidation()}</span> : ""}
                        <Label className="col-form-label" for="recipient-name">Valid From</Label>
                        <Input className="form-control form-control-inverse btn-square" min={moment().format('YYYY-MM-DD')} name="select" type="DATE"
                            value={formVar.validFrom} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, validFrom: e.target.value }))} />
                        {submit && fromdateValidation()? <span className='d-block font-danger'>{fromdateValidation()}</span> : ""}
                        <Label className="col-form-label" for="recipient-name">Valid To</Label>
                        <Input className="form-control form-control-inverse btn-square" min={moment().format('YYYY-MM-DD')} name="select" type="DATE"
                            value={formVar.validTo} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, validTo: e.target.value }))} />
                        {submit && todateValidation()? <span className='d-block font-danger'>{todateValidation()}</span> : ""}
                    </FormGroup>
                </Form>
                <ModalFooter>
                    <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
                    <Btn attrBtn={{ color: 'primary', onClick: submitMenu }}>Save Changes</Btn>
                </ModalFooter>
            </CommonModal>

        </Fragment>
    );
};

export default CouponTable;
